<template>
	<section>
		<div class="header">
			<div v-if="projectData.prevProjectUrl" class="top-arrows">
				<router-link :to="`/project${projectData.prevProjectUrl}`">
					<img
						src="/static/icons/arrow.svg"
						alt="Стрелка ведущая к предыдущему проекту"
					/>
				</router-link>
				<router-link :to="`/project${projectData.nextProjectUrl}`">
					<img
						src="/static/icons/arrow.svg"
						alt="Стрелка ведущая к следующему проекту"
					/>
				</router-link>
			</div>
			<div class="backdoor-image full-banner">
				<video
					v-if="fullBanner"
					:src="projectData.banner"
					muted
					loop
					playsinline
					autoplay
					class="banner-img fade"
				></video>
				<img
					v-else
					:src="projectData.banner"
					class="banner-img fade"
					alt="Фоновое изображение проекта"
				/>
			</div>
			<div class="header-inner">
				<img
					v-if="projectData.logo"
					:src="projectData.logo"
					alt="Логотип проекта"
					class="logo-image"
				/>
				<div class="txt-info">
					<p class="tag">{{ projectData.type }}</p>
					<h1 v-if="mobile" class="fadeSpanList">
						<p
							v-for="(w, k) in projectData.name.split(' ')"
							:key="k"
							:class="{ longName: w.length > 8 }"
						>
							<span v-for="(l, j) in w" :key="j">
								{{ l }}
							</span>
						</p>
					</h1>
					<h1 v-else class="fadeSpanList">
						<span v-for="(l, k) in projectData.name" :key="k">
							{{ l }}
						</span>
					</h1>
				</div>
				<div class="hrefs">
					<a
						v-if="projectData.siteUrl || projectData.siteUrl"
						class="a-circle"
						:href="
							projectData.siteUrl
								? projectData.siteUrl
								: `mailto:${projectData.email}`
						"
					>
						<p>
							{{
								projectData.siteUrl
									? `${$t("siteProject")} →`
									: projectData.email
							}}
						</p>
					</a>
					<template v-for="(s, k) in projectData.socials" :key="k">
						<a
							v-if="!!projectData.socials[k]"
							class="social"
							:href="s"
						>
							<img
								:src="`/static/icons/${k}.svg`"
								:alt="'Ссылка на' + k"
							/>
						</a>
					</template>
				</div>
			</div>
		</div>
		<div class="content">
			<template v-for="(b, k) in projectData.blocks" :key="k">
				<description
					v-if="b.name === 'subheader-text-w-sticker'"
					:data="b.data"
					:scroll="scroll"
				/>
				<our-events
					v-else-if="b.name === 'links-list'"
					:data="b.data"
				/>
				<carousel-component
					v-else-if="b.name === 'gallery'"
					:data="b.data"
				/>
				<numbers
					v-else-if="b.name === 'numbers'"
					:data="b.data"
					:scroll="scroll"
				/>
				<team
					v-else-if="b.name === 'projectTeam'"
					:data="b.data"
					:scroll="scroll"
				/>
				<one-video v-else-if="b.name === 'videoBlock'" :data="b.data" />
				<CircleBlocks
					v-else-if="b.name === 'massMedia'"
					:data="b.data"
				/>
				<quote v-else-if="b.name === 'quote'" :data="b.data" />
				<what-next v-else-if="b.name === 'whatsNext'" :data="b.data" />
				<press-kit v-else-if="b.name === 'pressKit'" :data="b.data" />
				<infinite-text-line
					v-else-if="b.name === 'partners'"
					:data="b.data"
				/>
				<emails-blocks
					v-else-if="b.name === 'contacts-emails'"
					:data="b.data"
				/>
				<Employees
					v-else-if="b.name === 'project-employees'"
					:data="b.data"
				/>
				<Map v-else-if="b.name === 'map'" :data="b.data" />
			</template>
			<div class="next-container">
				<router-link
					:to="`/project${projectData.nextProjectUrl}`"
					class="next"
				>
					NEXT →
				</router-link>
			</div>
			<Footer />
		</div>
	</section>
</template>

<script>
import Description from "../components/Blocks/Description.vue";
import OurEvents from "../components/Blocks/OurEvents.vue";
import CarouselComponent from "@/components/Blocks/carousel/CarouselComponent";
import Numbers from "../components/Blocks/Numbers.vue";
import Team from "../components/Blocks/Team.vue";
import CircleBlocks from "../components/Blocks/CircleBlocks/CircleBlocks.vue";
import Map from "../components/Blocks/Map.vue";
import Quote from "../components/Blocks/Quote.vue";
import WhatNext from "../components/Blocks/WhatNext.vue";
import PressKit from "../components/Blocks/PressKit.vue";
import Footer from "../components/Blocks/Footer.vue";
import OneVideo from "../components/Blocks/OneVideo.vue";
import { http } from "../components/Services/httpService";
import anmMixin from "@/mixins/anmMixin";
import InfiniteTextLine from "../components/Blocks/InfiniteTextLine.vue";
import EmailsBlocks from "../components/Blocks/EmailsBlocks.vue";
import Employees from "@/components/Blocks/Employees";
export default {
	name: "Project",
	mixins: [anmMixin],
	components: {
		Description,
		OurEvents,
		CarouselComponent,
		Numbers,
		Team,
		CircleBlocks,
		Map,
		Quote,
		WhatNext,
		PressKit,
		Footer,
		OneVideo,
		InfiniteTextLine,
		EmailsBlocks,
		Employees,
	},
	props: {
		scroll: {
			default: 0,
		},
	},
	data() {
		return {
			projectData: {
				name: "",
				type: "",
				logo: "",
				siteUrl: "",
				inProgress: true,
				fullScreenBanner: true,
				email: "",
				socials: {
					instagram: "",
					facebook: "",
					vk: "",
					telegram: "",
				},
				prevProjectUrl: "",
				nextProjectUrl: "",
				blocks: [],
			},
			mobile: false,
		};
	},
	methods: {
		saveData(data) {
			this.mobile = window.innerWidth < 1024;
			this.projectData = {
				...data,
				fullScreenBanner: this.mobile ? true : data.fullScreenBanner,
			};
			document.title = "P/25 | " + data.name;
		},
		getData() {
			const locale = localStorage.getItem("locale");
			http.get(
				`/api/projects/${this.$route.params.id}?lang=${
					locale ? locale : "ru-RU"
				}`
			).then((response) => {
				if (response.status < 400) {
					this.$el.scrollTop = 0;
					this.saveData(response.data);
				}
			});
		},
	},
	computed: {
		fullBanner() {
			if (!this.projectData.banner) {
				return false;
			}
			const splt = this.projectData.banner.split(".");
			return (
				splt[splt.length - 1] !== "webp" &&
				splt[splt.length - 1] !== "jp2"
			);
		},
	},
	watch: {
		scroll(newVal) {
			if (!this.mobile) {
				this.smoothScrlAnm(
					".backdoor-image",
					"opacity",
					0.35 - newVal / (window.innerHeight * 1.8)
				);
			}
		},
	},
	mounted() {
		if (this.projectData.name.length === 0) {
			this.getData();
		}
		window.scrollTo(0, 0);
	},
	beforeRouteEnter(to, from, next) {
		const locale = localStorage.getItem("locale");
		http.get(
			`/api/projects/${to.params.id}?lang=${locale ? locale : "ru-RU"}`
		).then((response) => {
			if (response.status < 400) {
				next((vm) => {
					vm.$el.scrollTop = 0;
					vm.saveData(response.data);
				});
			}
		});
	},
	// beforeRouteUpdate() {
	// 	this.projectData = {
	// 		name: "",
	// 		type: "",
	// 		logo: "",
	// 		siteUrl: "",
	// 		inProgress: true,
	// 		email: "",
	// 		socials: {
	// 			instagram: "",
	// 			facebook: "",
	// 			vk: "",
	// 			telegram: "",
	// 		},
	// 		prevProjectUrl: "",
	// 		nextProjectUrl: "",
	// 		blocks: [],
	// 	};
	// },
};
</script>

<style lang="scss" scoped>
@import "../components/styles/config.scss";

.header {
	width: 100%;
	height: 100vh;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
}

.header-inner {
	display: grid;
	width: max-content;
	position: relative;
}

.txt-info {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

h1 {
	font-size: ptr(70px);
	text-transform: uppercase;
	line-height: ptr(70px);
	font-weight: 500;
	margin: 0;
	white-space: pre;
	span {
		opacity: 0;
		display: inline-block;
		&:nth-child(1) {
			text-transform: uppercase;
		}
	}
}
.tag {
	font-size: ptr(14px);
	display: block;
	opacity: 0;
	position: relative;
	font-weight: 500;
	color: #989898;
	top: ptr(-30px);
	animation: fade 1s 0.8s $anmTrs forwards;
}

.hrefs {
	display: grid;
	grid-auto-flow: column;
	grid-auto-columns: max-content;
	align-items: center;
	gap: ptr(12px);
	justify-self: center;
	position: relative;
	top: ptr(35px);
	@for $i from 1 through 10 {
		a:nth-child(#{$i}) {
			animation-delay: 1s + $i * 0.3s;
		}
	}
}
.a-circle {
	border-radius: ptr(50px);
	color: white;
	font-size: ptr(12px);
	background: rgba(255, 255, 255, 0.2);
	min-height: unset;
	text-decoration: none;
	opacity: 0;
	padding: ptr(8px) ptr(30px);
	animation: fade 1s $anmTrs forwards;
	transition: 0.3s $anmTrs;
	p {
		position: relative;
		pointer-events: none;
		z-index: 2;
		transition: $trs;
	}
	&:hover {
		background: rgba(255, 255, 255, 0.3);
	}
}
.social {
	opacity: 0;
	animation: fade 1s $anmTrs forwards;
	transition: 0.4s $anmTrs;
	color: #6d6d6d;
	text-transform: uppercase;
	font-weight: 500;
	min-width: ptr(30px);
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: ptr(30px);
	background: rgba(255, 255, 255, 0.2);
	border-radius: 50%;
	font-size: ptr(14px);
	img {
		transition: 0.4s $anmTrs;
		width: ptr(11px);
		height: ptr(12px);
		object-fit: contain;
		object-position: center;
		filter: invert(1);
	}
	// &:nth-child(4) img {
	// 	margin: ptr(2px) 0 0 ptr(0.5px);
	// }
	&:nth-child(5) img {
		margin: ptr(1px) ptr(2px) 0 0;
	}
	&:hover {
		background: rgba(255, 255, 255, 0.3);
	}
}
.logo-image {
	position: absolute;
	width: ptr(205px);
	height: ptr(75px);
	object-fit: contain;
	top: ptr(-242px);
	justify-self: center;
	opacity: 0;
	animation: fadeMin 1.5s 1s $anmTrs forwards;
}
@keyframes fadeMin {
	from {
		opacity: 0;
	}
	to {
		opacity: 0.4;
	}
}
.backdoor-image {
	width: 100%;
	height: ptr(226px);
	clip-path: inset(0);
	position: fixed;
	bottom: 0;
	img {
		bottom: ptr(-400px);
		position: absolute;
		width: 100%;
	}
}
.full-banner {
	height: 100vh;
	bottom: unset;
	opacity: 0.35;
	&::after {
		content: "";
		position: absolute;
		width: 100%;
		bottom: 0;
		height: 30vh;
		background: linear-gradient(rgba(0, 0, 0, 0), black);
	}
	img {
		top: 0;
	}
}
.top-arrows {
	position: absolute;
	top: ptr(40px);
	right: ptr(50px);
	display: flex;
	z-index: 3;
	a {
		border: none;
		background: none;
		padding: 0;
		img {
			transform: rotate(90deg);
			pointer-events: none;
			width: ptr(30px);
		}
	}
	a:nth-child(2) img {
		margin-left: ptr(18px);
		transform: rotate(-90deg);
	}
}
.content {
	width: 100%;
	box-sizing: border-box;
	display: grid;
	justify-content: center;
	gap: ptr(280px);
	margin-top: ptr(100px);
	grid-template-columns: 100%;
	overflow: hidden;
}
@media (max-width: 1024px) {
	.txt-info {
		width: 100vw;
	}
	.backdoor-image {
		position: absolute;
	}
	h1 {
		width: 100vw;
		box-sizing: border-box;
		font-size: ptr(52px);
		line-height: ptr(56px);
		margin: 0 0 ptr(44px);
		text-align: center;
		white-space: break-spaces;
		p {
			margin-right: ptr(10px);
			display: inline-block;
		}
	}
	.tag {
		font-size: ptr(12px);
		font-weight: 300;
		top: ptr(-8px);
	}
	.longName {
		font-size: ptr(40px);
	}
	.logo-image {
		display: none;
	}
	.backdoor-image {
		img {
			object-fit: cover;
			top: 0;
			height: 100%;
		}
	}
	.top-arrows {
		display: none;
	}
	.hrefs {
		right: unset;
		top: ptr(0px);
		display: flex;
		width: fit-content;
		flex-wrap: wrap;
		left: ptr(-4px);
		a {
			color: white;
		}
	}
	.content {
		justify-content: flex-start;
		margin-top: ptr(70px);
		gap: ptr(146px);
	}
}
</style>
