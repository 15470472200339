<template>
	<div class="content">
		<div class="img-container">
			<img :src="info.image" alt="Картинка к новости" />
		</div>
		<h2>
			<span v-for="(p, k) in infoData.title" :key="k">
				{{ p }}
			</span>
		</h2>
		<p class="description">
			<span v-for="(p, k) in infoData.content" :key="k">
				{{ p }}
			</span>
		</p>
		<router-link class="HOVER" :to="`/project${info.projectUrl}`">
			<p class="txt">{{ $t("aboutProject") }} →</p>
			<span></span>
		</router-link>
	</div>
</template>

<script>
import anmMixin from "@/mixins/anmMixin";
export default {
	mixins: [anmMixin],
	props: {
		info: {
			default: () => ({}),
		},
		top: {
			default: false,
		},
	},
	data() {
		return {
			infoData: {
				title: [],
				content: [],
			},
		};
	},
	mounted() {
		this.setObserverFunction([
			{
				name: ".img-container",
				animationName: "scaleDown",
			},
			{
				name: "h2",
				animationName: "fadeSpanList",
			},
			{
				name: ".description",
				animationName: "fadeSpanList",
			},
			{
				name: "a",
				animationName: "fade",
			},
		]);
	},
	created() {
		const mobile = window.innerWidth < 1024;
		this.infoData = {
			title: this.parseTxt(
				this.info.title,
				mobile ? 310 : 602,
				mobile ? 22 : 40
			),
			content: this.parseTxt(
				this.info.content,
				mobile ? 300 : 602,
				mobile ? 14 : 16
			),
		};
	},
};
</script>

<style lang="scss" scoped>
@import "./styles/config.scss";

.img-container {
	width: ptr(612px);
	height: ptr(358px);
	overflow: hidden;
	img {
		width: 100%;
		object-fit: cover;
		opacity: 0;
	}
}
.scaleDown {
	img {
		animation: scaleDown 2s cubic-bezier(0.33, 1, 0.68, 1) forwards;
	}
}

@keyframes scaleDown {
	from {
		opacity: 0;
		transform: scale(1.4);
	}
	50% {
		opacity: 1;
	}
	to {
		transform: scale(1);
		opacity: 1;
	}
}

h2 {
	font-size: ptr(40px);
	font-weight: 300;
	line-height: ptr(45px);
	display: flex;
	flex-direction: column;
	span {
		display: inline-block;
		opacity: 0;
	}
}

.description {
	font-size: ptr(16px);
	font-weight: 300;
	margin-bottom: ptr(87px);
	display: flex;
	flex-direction: column;
	span {
		opacity: 0;
	}
}

a {
	width: ptr(166px);
	height: ptr(46px);
	border: 2px solid rgba(255, 255, 255, 0.4);
	border-radius: ptr(100px);
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
	text-decoration: none;
	opacity: 0;
	margin-bottom: ptr(20px);
	transition: 0.3s $anmTrs;
	.txt {
		position: relative;
		z-index: 2;
		color: white;
		pointer-events: none;
		transition: 0.5s;
	}
}

@media (max-width: 1024px) {
	.img-container {
		width: ptr(314px);
		height: ptr(187px);
	}

	h2 {
		font-size: ptr(22px);
		line-height: ptr(31px);
	}

	p {
		font-size: ptr(14px);
		margin-bottom: ptr(66px);
	}
}
</style>
