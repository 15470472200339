import { createRouter, createWebHistory } from "vue-router";
import Main from "../views/Main.vue";
import Project from "@/views/Project";
import About from "@/views/About";
import Timelife from "@/views/TimeLife";
import Contact from "@/views/Contact";
import Redirect from "@/views/Redirect";

const routes = [
	{
		path: "/",
		name: "Main",
		component: Main,
	},
	{
		path: "/project/:id",
		name: "Project",
		component: Project,
	},
	{
		path: "/vacancy",
		component: Redirect,
	},
	{
		path: "/About",
		name: "About",
		component: About,
	},
	{
		path: "/Timelife",
		name: "Timelife",
		component: Timelife,
	},
	{
		path: "/Contact",
		name: "Contact",
		component: Contact,
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

export default router;
