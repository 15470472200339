<template>
	<div class="block-container">
		<div class="mail" :class="{ off: offEmail }">
			<p>Placebo/25 ®</p>
			<span>2015—2024 © </span>
		</div>
		<div class="footer-info">
			<div class="projects-list">
				<router-link
					:to="`/project` + p.url"
					v-for="(p, k) in projects"
					:key="k"
				>
					{{ p.name }}
				</router-link>
			</div>
			<router-link :to="`/Contact`" class="mobile-mail">
				<span>МОСКВА</span>
				<span>САНКТ-ПЕТЕРБУРГ</span>
				<span>ЭЙНДХОВЕН</span>
			</router-link>
			<div class="legal-info">
				<p>ОГРН {{ footer.ogrn }}</p>
				<p>ИНН {{ footer.inn }}</p>
				<p>КПП {{ footer.kpp }}</p>
				<div class="policy">
					Политика конфиденциальности
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { http } from "../Services/httpService";
export default {
	props: {
		offEmail: {
			default: false,
		},
	},
	data() {
		return {
			footer: {},
			projects: [],
		};
	},
	methods: {
		getProjects() {
			http.get(`/api/projects?lang=${this.$i18n.locale}`).then(
				(response) => {
					if (response.status < 400) {
						this.projects = response.data.projects;
					}
				}
			);
		},
		getFooterInfo() {
			http.get(`/api/contacts/footer?lang=${this.$i18n.locale}`).then(
				(response) => {
					if (response.status) {
						this.footer = response.data;
					}
				}
			);
		},
	},
	mounted() {
		this.getFooterInfo();
		this.getProjects();
	},
};
</script>

<style lang="scss" scoped>
@import "../styles/blockStyles.scss";
@import "../styles/config.scss";

.block-container {
	padding: ptr(52px) 0 ptr(40px) ptr(279px);
	display: grid;
	grid-template-columns: ptr(590px) 1fr;
	gap: ptr(80px);
	position: relative;
	&::before {
		content: "";
		top: 0;
		left: ptr(279px);
		position: absolute;
		width: calc(100% - #{ptr(279px)});
		height: 1px;
		background: #979797;
	}
}
.mail {
	p {
		color: $text-color;
		font-size: ptr(26px);
		font-weight: 300;
		text-transform: uppercase;
		text-decoration: none;
	}
	span {
		font-size: ptr(12px);
		margin-top: ptr(5px);
	}
}
.off {
	opacity: 0;
	pointer-events: none;
}
.footer-info {
	display: grid;
	gap: ptr(80px);
	grid-column: repeat(2, max-content);
}
.phone {
	font-size: ptr(43px);
	font-weight: 300;
}
.projects-list {
	width: ptr(366px);
	text-transform: uppercase;
	font-weight: 300;
	display: grid;
	gap: ptr(3px) ptr(41px);
	grid-auto-flow: column;
	grid-template-rows: repeat(9, max-content);
	a {
		font-size: ptr(14px);
		color: $text-color;
		text-decoration: none;
	}
}

.mobile-mail {
	grid-column: 2;
	display: grid;
	gap: ptr(5px);
	color: #5b5b5b;
	font-weight: 400;
	text-transform: uppercase;
	text-decoration: none;
	font-size: ptr(15px);
	grid-auto-rows: min-content;
}

.legal-info {
	padding-top: ptr(60px);
	display: grid;
	grid-template-columns: repeat(3, max-content);
	gap: ptr(10px);
	font-size: ptr(10px);
	font-weight: 300;
}
.policy {
	grid-column: span 3;
	text-transform: uppercase;
}

@media (max-width: 1024px) {
	.block-container {
		padding: ptr(42px) 0 ptr(40px) ptr(15px);
		grid-template-columns: 1fr;
		gap: ptr(80px);
		&::before {
			left: ptr(15px);
			width: calc(100% - #{ptr(15px)});
		}
	}
	.footer-info {
		display: grid;
		gap: ptr(37px);
	}
	.projects-list {
		width: ptr(326px);
	}
	.mobile-mail {
		grid-column: 1;
	}
	.phone {
		font-size: ptr(18px);
		font-weight: 300;
	}
	.adress {
		width: ptr(253px);
		font-size: ptr(18px);
	}
}
</style>
