<template>
	<li
		:class="{ 'show-info': open }"
		:style="{
			height: hideHeight + 'px',
		}"
		@click="goToProject"
		@mouseenter="checkCircle"
		@mousemove="getPos"
		@mouseleave="hover = false"
	>
		<div @click.stop="openInfo" class="main-info">
			<div class="type">
				{{ data.type }}
			</div>
			<div class="name">
				{{ data.name }}
				<p v-if="data.inProgress" class="in-progress">
					<span>
						in progress
					</span>
				</p>
				<div class="type">
					{{ data.type }}
				</div>
			</div>
			<img
				src="/static/icons/arrow.svg"
				class="arrow"
				@mouseenter="hover = false"
				@mouseleave="checkCircle"
				@click.stop="
					openInfo();
					hover = false;
				"
				:class="{ open: open }"
				alt="Стрелка закрывающая выбранный проект"
			/>
		</div>
		<div class="hide-info">
			<a
				class="explore-circle"
				:class="{ unhover: !hover }"
				:style="{
					transform: `translate3d(calc(${circle.x}px - 50%),calc(${circle.y}px - 50%),0)`,
				}"
			>
				<p>Click</p>
				<p>to explore</p>
				<p>→</p>
			</a>
			<p class="description">
				{{ data.description }}
			</p>
			<img
				v-if="data.logo"
				class="logo"
				:src="data.logo"
				alt="Логотип проекта"
			/>
			<router-link :to="'/project' + data.url" class="more-info">
				MORE INFO →
			</router-link>
		</div>
	</li>
</template>

<script>
export default {
	props: {
		data: {
			default: () => ({
				type: "",
				name: "",
				source: "",
				description: "",
				inProgress: false,
				logo: "",
			}),
		},
	},
	data() {
		return {
			open: false,
			hideHeight: 0,
			circle: {
				el: null,
				x: 0,
				y: 0,
			},
			hover: false,
			idle: true,
			mousePos: {
				x: 0,
				y: 0,
			},
		};
	},
	methods: {
		openInfo(e) {
			this.open = !this.open;
			const dsc = this.$el.querySelector(".hide-info");
			const mn = this.$el.querySelector(".main-info");
			this.checkCircle(e);
			if (this.open) {
				this.hideHeight = mn.scrollHeight + dsc.scrollHeight;
			} else {
				this.hideHeight = mn.scrollHeight;
			}
		},
		goToProject() {
			if (this.open) {
				this.$router.push(`/project${this.data.url}`);
			}
		},
		mainLoop() {
			if (this.idle || !this.open) return;
			const targetPos = this.mousePos;

			let dirVector = {
				x: targetPos.x - this.circle.x,
				y: targetPos.y - this.circle.y,
			};

			dirVector = {
				x: dirVector.x * 0.1,
				y: dirVector.y * 0.1,
			};

			this.circle.x += dirVector.x;
			this.circle.y += dirVector.y;

			const norm = Math.sqrt(
				dirVector.x * dirVector.x + dirVector.y * dirVector.y
			);

			if (!this.hover && norm < 1) {
				this.idle = true;
				this.circle = targetPos;
			}

			requestAnimationFrame(this.mainLoop);
		},
		checkCircle(e) {
			if (this.open) {
				this.hover = true;
				if (this.idle) {
					this.idle = false;
					this.mainLoop();
				}
				this.getPos(e);
			}
		},
		getPos(e) {
			if (e.target.className !== "arrow open") {
				this.mousePos = {
					x: e.offsetX,
					y: e.offsetY,
				};
			}
		},
	},
	mounted() {
		setTimeout(() => {
			this.hideHeight = this.$el.querySelector(".main-info").scrollHeight;
		}, 0);
		this.circle.el = this.$el.querySelector(".explore-circle");
	},
};
</script>

<style lang="scss" scoped>
@import "./styles/config.scss";

li {
	list-style: none;
	overflow: hidden;
	transition: $trs;
	position: relative;
	opacity: 0;
	&::before {
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		width: 0%;
		height: 1px;
		background: #979797;
	}
}
.main-info {
	display: grid;
	grid-template-columns: ptr(661px) auto min-content;
	padding: ptr(27px) 0 ptr(43px);
}
.arrow {
	width: ptr(46px);
	transform: rotate(-90deg);
	padding: ptr(27px) ptr(34px);
	transition: $trs;
	position: relative;
	top: ptr(-27px);
	pointer-events: none;
	z-index: 3;
}
.type {
	font-size: ptr(14px);
	pointer-events: none;
	font-weight: 300;
}
.name {
	font-size: ptr(60px);
	font-weight: 300;
	text-transform: uppercase;
	pointer-events: none;
	position: relative;
	width: fit-content;
	.type {
		display: none;
	}
}
.hide-info {
	padding-left: ptr(663px);
	position: relative;
	top: ptr(-30px);
	opacity: 0;
	transition: $trs;
}
.show-info {
	overflow: visible;
	.hide-info {
		opacity: 1;
	}
	.href {
		opacity: 0;
		animation: minfadeRight 1s $anmTrs forwards;
	}
	.description {
		opacity: 0;
		animation: minfadeRight 1s 0.2s $anmTrs forwards;
	}
	.logo {
		opacity: 0;
		animation: minfadeUp 0.8s 0.5s $anmTrs forwards;
	}
	&:hover {
		* {
			pointer-events: none;
			.arrow {
				pointer-events: all;
			}
		}
		.explore-circle {
			opacity: 1;
		}
	}
}
.description {
	margin: 0 0;
	padding-bottom: ptr(70px);
	width: ptr(341px);
	white-space: pre-wrap;
}
.in-progress {
	display: inline-flex;
	background: #0b22ca;
	color: white;
	text-transform: uppercase;
	font-size: ptr(10px);
	padding: ptr(4.5px) ptr(16px) ptr(5.5px);
	border-radius: ptr(6px);
	position: relative;
	top: ptr(-30px);
	left: ptr(20px);
	span {
		animation: prg 6s infinite;
	}
}
@keyframes prg {
	from {
		clip-path: inset(0 ptr(60px) 0 0);
	}
	2%,
	3% {
		clip-path: inset(0 ptr(55px) 0 0);
	}

	4%,
	5% {
		clip-path: inset(0 ptr(50px) 0 0);
	}

	6%,
	7% {
		clip-path: inset(0 ptr(45px) 0 0);
	}

	8%,
	9% {
		clip-path: inset(0 ptr(40px) 0 0);
	}

	10%,
	11% {
		clip-path: inset(0 ptr(35px) 0 0);
	}

	12%,
	13% {
		clip-path: inset(0 ptr(30px) 0 0);
	}

	14%,
	15% {
		clip-path: inset(0 ptr(25px) 0 0);
	}

	16%,
	17% {
		clip-path: inset(0 ptr(20px) 0 0);
	}

	18%,
	19% {
		clip-path: inset(0 ptr(15px) 0 0);
	}

	20%,
	21% {
		clip-path: inset(0 ptr(10px) 0 0);
	}

	22%,
	23% {
		clip-path: inset(0 ptr(5px) 0 0);
	}

	25%,
	70% {
		clip-path: inset(0 ptr(0px) 0 0);
	}
	71%,
	71.5% {
		clip-path: inset(0 ptr(5px) 0 0);
	}

	72%,
	72.5% {
		clip-path: inset(0 ptr(10px) 0 0);
	}

	73%,
	73.5% {
		clip-path: inset(0 ptr(15px) 0 0);
	}

	74%,
	74.5% {
		clip-path: inset(0 ptr(20px) 0 0);
	}

	75%,
	75.5% {
		clip-path: inset(0 ptr(25px) 0 0);
	}

	76%,
	76.5% {
		clip-path: inset(0 ptr(30px) 0 0);
	}

	77%,
	77.5% {
		clip-path: inset(0 ptr(35px) 0 0);
	}

	78%,
	78.5% {
		clip-path: inset(0 ptr(40px) 0 0);
	}

	79%,
	79.5% {
		clip-path: inset(0 ptr(45px) 0 0);
	}

	80%,
	80.5% {
		clip-path: inset(0 ptr(50px) 0 0);
	}
	86%,
	100% {
		clip-path: inset(0 ptr(60px) 0 0);
	}
}
.href {
	display: inline-block;
	color: $text-color;
	text-decoration: none;
	font-weight: 300;
	img {
		margin-left: ptr(2px);
		width: ptr(13px);
		transform: translateY(ptr(1px)) rotate(-45deg);
	}
}
.explore-circle {
	border-radius: 50%;
	border: 2px solid #979797;
	background: $back-color;
	left: ptr(0px);
	top: ptr(-140px);
	transition: width $trs, height $trs, opacity $trs;
	position: absolute;
	z-index: 2;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: ptr(14px);
	overflow: hidden;
	text-transform: uppercase;
	font-weight: 600;
	box-sizing: border-box;
	pointer-events: none;
	width: ptr(156px);
	height: ptr(156px);
	p {
		width: ptr(94px);
		&:nth-child(1) {
			margin: ptr(5px) 0 0 ptr(20px);
		}
		&:nth-child(3) {
			margin-left: ptr(10px);
		}
	}
}
.more-info {
	display: none;
}
.unhover {
	opacity: 0 !important;
	pointer-events: none;
}
.logo {
	width: ptr(107px);
	margin-bottom: ptr(50px);
}
.open {
	transform: rotate(0deg);
}

@media (max-width: 1024px) {
	li::before {
		background: #ffffff;
	}
	.main-info {
		display: grid;
		grid-template-columns: 1fr min-content;
		padding: ptr(20px) ptr(15px) ptr(30px) 0;
	}
	.show-info:hover * {
		pointer-events: all;
	}
	.name {
		font-size: ptr(30px);
		display: grid;
		gap: ptr(5px);
		.type {
			display: block;
		}
	}
	.type {
		display: none;
		color: #6e6e6e;
		font-size: ptr(12px);
		margin-top: ptr(5px);
		text-transform: capitalize;
	}
	.arrow {
		width: ptr(22px);
		padding: 0;
		top: ptr(0px);
	}
	.hide-info {
		padding-left: 0;
		display: grid;
	}
	.description {
		width: 100%;
		font-size: ptr(14px);
		padding: 0;
		margin: ptr(26px) 0 ptr(56px);
	}
	.more-info {
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		width: ptr(118px);
		height: ptr(118px);
		border: 2px solid #979797;
		background: #2a2a2a;
		font-size: ptr(10px);
		font-weight: 600;
		justify-self: center;
		color: $text-color;
		text-decoration: none;
		margin: 0 0 ptr(57px) ptr(-30px);
	}
	.logo {
		width: ptr(88px);
	}
	.in-progress {
		width: ptr(60px);
		height: ptr(18px);
		padding: 0;
		align-items: center;
		justify-content: center;
		grid-column: 2;
		margin-top: ptr(6px);
		border-radius: ptr(4px);
		top: 0;
		font-size: ptr(8px);
		left: 0;
	}
	.href {
		display: none;
	}
	.explore-circle {
		display: none;
	}
}
</style>
