<template>
	<div class="block-container">
		<h2>
			<span>
				OUR
			</span>
			<br />
			<span>
				EVENTS
			</span>
		</h2>
		<ul class="events-container">
			<li v-for="(e, k) in data.links" :key="k" class="event">
				<a target="_blank" :href="e.url">
					{{ e.text }}
				</a>
				<img
					src="/static/icons/arrow.svg"
					alt="Стрелка обозначающая переход по ссылке к событию"
				/>
			</li>
		</ul>
	</div>
</template>

<script>
import anmMixin from "@/mixins/anmMixin";
export default {
	mixins: [anmMixin],
	props: {
		data: {
			default: () => ({}),
		},
	},
	mounted() {
		this.setObserverFunction([
			{
				name: "h2",
				id: 1,
				animationName: "sideWords",
				distance: 300,
			},
			{
				name: ".events-container",
				id: 2,
				animationName: "fadeList",
				distance: 300,
			},
		]);
	},
};
</script>

<style lang="scss" scoped>
@import "../styles/blockStyles.scss";
@import "../styles/config.scss";

h2 {
	font-size: ptr(60px);
	font-weight: 300;
	line-height: ptr(66px);
	width: fit-content;
	margin: 0 0 ptr(150px);
	span {
		opacity: 0;
	}
	span:nth-child(3) {
		margin-left: ptr(60px);
	}
}
.events-container {
	display: grid;
	gap: ptr(5px);
	li {
		opacity: 0;
	}
}
.event {
	display: flex;
	align-items: flex-start;
	a {
		color: $text-color;
		text-decoration: none;
		font-size: ptr(80px);
		text-transform: uppercase;
	}
	img {
		opacity: 0;
		transition: $trs;
		width: ptr(50px);
		position: relative;
		top: ptr(13px);
		left: ptr(20px);
		transform: rotate(-135deg);
	}
	&:hover img {
		opacity: 1;
	}
}
@media (max-width: 1024px) {
	h2 {
		line-height: ptr(41px);
		font-size: ptr(45px);
		font-weight: 500;
		margin: 0 0 ptr(87px);
		span:nth-child(3) {
			margin-left: ptr(40px);
		}
	}
	.event {
		a {
			font-size: ptr(31px);
		}
		img {
			width: ptr(22px);
			top: ptr(4px);
			left: ptr(10px);
			opacity: 1;
		}
	}
}
</style>
