import { createApp } from "vue";
import { createI18n } from "vue-i18n";
import App from "./App.vue";
import router from "./router";
import VueYandexMetrika from "./components/metrika";
import VueGtag, { trackRouter } from "vue-gtag-next";

const i18n = createI18n({
	locale: "ru-RU",
	fallbackLocale: "ru-RU",
	messages: {
		"ru-RU": {
			ecosystem: {
				htext:
					"Экосистема из брендов, качественно меняющих окружающее пространство. Решения, которые охватывают все сферы жизни, окружая клиента сервисом\nна 360°",
				description:
					"Коллекция брендов Placebo/25 рождается на пересечении этих смысловых траекторий.\n\nНаши продукты объединяет визуальная нетривиальность, атмосферность, интеллектуальные персонализированные решения и стремление достичь исключительного уровня сервиса.",
			},
			pages: {
				projects: "Проекты",
				about: "О нас",
				timelife: "Timelife",
				contacts: "Контакты",
			},
			aboutProject: "О ПРОЕКТЕ",
			topTxt: [
				"Прогрессивные идеи, воссозданные в собственных",
				"продуктах и решениях",
			],
			MM: ["СМИ", "О ПРОЕКТЕ"],
			map: ["КАРТА", "ПРОЕКТА"],
			whatNext: ["ЧТО", "ДАЛЬШЕ"],
			team: ["КОМАНДА", "ПРОЕКТА"],
			cAndh: ["КУЛЬТУРА", "ИСТОРИЯ"],
			wePlaceboDesc: "История, которая началась с вдохновения.",
			hStudied:
				"Мы изучили культуру и историю, которые десятилетиями формировали ценности и устои, становясь драйверами развития общества.",
			ourMission:
				"НАША МИССИЯ — СОЗДАВАТЬ ПРОДУКТЫ\nНА ПЕРЕСЕЧЕНИИ ДИЗАЙНА И КИНЕСТЕТИКИ, КОТОРЫЕ НАПОЛНЯЮТ ЖИЗНЬ ЭНЕРГИЕЙ И ТВОРЧЕСТВОМ.",
			people: "Люди —\nглавная инвестиция.",
			coop: "сотрудничество",
			sendCV: "Отправить CV",
			show: ["Показывать всё", "Показывать только"],
			siteProject: "Сайт проекта",
			scrollTxt:
				"Скролл вниз расскажет тебе нашу историю.\nСкролл вверх откроет наши планы",
			careful:
				"Мы бережно относимся к команде\nи растим профессионалов изнутри.\n\nРазвиваясь, мы собрали команду единомышленников, увлеченных lifestyle-культурой, искусством и технологиями.",
		},
		"en-US": {
			ecosystem: {
				htext:
					"An ecosystem of brands that qualitatively change the surrounding space.\nSolutions that cover all spheres of life,\nsurrounding the client\nwith 360° service",
				description:
					"The Placebo/25 brand collection is born at the intersection of these semantic trajectories. Our products are united by visual non-triviality, atmosphere, intelligent personalized solutions and the desire to achieve an exceptional level of service.",
			},
			pages: {
				projects: "Projects",
				about: "About",
				timelife: "Timelife",
				contacts: "Contacts",
			},
			aboutProject: "ABOUT PROJECT",
			topTxt: [
				"Progressive ideas recreated in their own ",
				"products and solutions",
			],
			MM: ["MEDIA", "ABOUT THE PROJECT"],
			map: ["PROJECT", "MAP"],
			whatNext: ["WHAT", "NEXT"],
			team: ["PROJECT", "TEAM"],
			cAndh: ["CULTURE", "HISTORY"],
			wePlaceboDesc: "A story that began with inspiration",
			hStudied:
				"We have studied the culture and history that have been forming values and foundations for decades, becoming drivers of the development of society.",
			ourMission:
				"OUR MISSION IS TO CREATE PRODUCTS AT THE INTERSECTION OF DESIGN AND KINESTHETICS THAT FILL LIFE WITH ENERGY AND CREATIVITY.",
			people: "People —\nthe main investment",
			sendCV: "Send CV",
			coop: "cooperation",
			siteProject: "Project website",
			show: ["Show all", "Show only"],
			scrollTxt:
				"Scroll down will tell you our story.\nScroll up will reveal our plans",
			careful:
				"We take care of the team \nand raise professionals from the inside out.\n\nWe have gathered a team of like-minded people who are passionate about lifestyle culture, art and technology.",
		},
	},
});

const app = createApp(App);

if (process.env.NODE_ENV === "production") {
	app.use(VueGtag, {
		property: { id: "UA-209682944-1" },
	});

	trackRouter(router);

	app.use(VueYandexMetrika, {
		id: 85808204,
		router: router,
		env: process.env.NODE_ENV,
		clickmap: true,
		trackLinks: true,
		accurateTrackBounce: true,
		webvisor: true,
	});
}

app.use(i18n)
	.use(router)
	.mount("#app");
