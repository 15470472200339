<template>
	<section>
		<div class="top-info">
			<router-link to="/">
				<img src="/static/icons/placebo.svg" alt="Placebo/25 logo" />
			</router-link>
			<div class="lang-selector desktop">
				<button
					class="lang fade"
					:class="{ selected: $i18n.locale === 'en-US' }"
					@click="setLocale('en-US')"
				>
					EN
				</button>
				<button
					class="lang fade"
					:class="{ selected: $i18n.locale === 'ru-RU' }"
					@click="setLocale('ru-RU')"
				>
					RU
				</button>
			</div>
		</div>
		<div
			@click="mobileMenuActive = !mobileMenuActive"
			class="mobile-active-btn"
			:class="{ 'close-btn': mobileMenuActive }"
		></div>
		<ul class="pages" :class="{ active: mobileMenuActive }">
			<li>
				<div class="projects-show">
					<router-link :to="'/#projects'" class="page">
						{{ $t("pages.projects") }}
					</router-link>
				</div>
				<div
					class="groups"
					:class="{ show: projectsOpen }"
					:style="`height: ${subHeight}px`"
				>
					<div
						v-for="(g, k) in sideInfo.groups"
						:key="k"
						class="group-container"
						:class="g.name === openedGroupName ? 'open' : 'closed'"
					>
						<div
							class="group-name"
							@click="
								openGroup(g.name, openedGroupName === g.name)
							"
						>
							<p>
								{{ g.name }}
							</p>
							<img
								class=""
								src="/static/icons/arrow.svg"
								alt=""
							/>
						</div>
						<ul class="sub-pages">
							<li v-for="(p, k) in g.projects" :key="k">
								<router-link
									:to="`/project/${p.url}`"
									class="page"
								>
									{{ p.name }}
								</router-link>
							</li>
						</ul>
					</div>
				</div>
			</li>
			<li>
				<router-link to="/About" class="page">{{
					$t("pages.about")
				}}</router-link>
			</li>
			<!-- <li>
				<router-link to="/Timelife" class="page">{{
					$t("pages.timelife")
				}}</router-link>
			</li> -->
			<li>
				<router-link to="/Contact" class="page">{{
					$t("pages.contacts")
				}}</router-link>
			</li>
			<li>
				<ul class="mobile-projects">
					<li v-for="(p, k) in allProjects" :key="k">
						<router-link
							:to="`/project${p.url}`"
							class="mob-project"
						>
							{{ p.name }}
						</router-link>
					</li>
				</ul>
			</li>
			<div class="lang-selector mobile">
				<button
					class="lang fade"
					:class="{ selected: $i18n.locale === 'en-US' }"
					@click="setLocale('en-US')"
				>
					EN
				</button>
				<button
					class="lang fade"
					:class="{ selected: $i18n.locale === 'ru-RU' }"
					@click="setLocale('ru-RU')"
				>
					RU
				</button>
			</div>
		</ul>
		<a class="inst" :href="sideInfo.instagram" target="_blank">
			Instagram <img src="/static/icons/arrow-thin.svg" alt="Instagram" />
		</a>
	</section>
</template>

<script>
import { http } from "./Services/httpService";
export default {
	props: {
		scroll: {
			default: 0,
		},
	},
	data() {
		return {
			projectsOpen: false,
			page: "",
			subHeight: 0,
			mobileMenuActive: false,
			sideInfo: {
				instagram: "",
				groups: [],
			},
			allProjects: [],
			openedGroupName: "",
		};
	},
	methods: {
		getInstagram() {
			http.get(`/api/contacts/sidebar?lang=${this.$i18n.locale}`).then(
				(response) => {
					if (response.status < 400) {
						this.sideInfo.instagram = response.data.instagram;
					}
				}
			);
		},
		openGroup(name, close = false) {
			if (close) {
				this.openedGroupName = "";
			} else {
				this.openedGroupName = name;
			}
			setTimeout(() => {
				const open = this.$el.querySelector(".open");
				const closed = this.$el.querySelectorAll(".closed");
				if (open) {
					open.childNodes[1].style.height =
						open.childNodes[1].scrollHeight + "px";
				}
				for (let g of closed) {
					g.childNodes[1].style.height = "0px";
				}
				this.getProjectsHeight();
			}, 5);
		},
		getProjects() {
			http.get(`/api/projects/grouped?lang=${this.$i18n.locale}`).then(
				(response) => {
					if (response.status < 400) {
						this.sideInfo.groups = response.data.groups;
						setTimeout(() => {
							this.getProjectsHeight();
						}, 0);
					}
				}
			);
			http.get(`/api/projects?lang=${this.$i18n.locale}`).then(
				(response) => {
					if (response.status < 400) {
						this.allProjects = response.data.projects;
					}
				}
			);
		},
		setLocale(lang) {
			localStorage.setItem("locale", lang);
			this.$router.go(0);
			this.$i18n.locale = lang;
		},
		getProjectsHeight() {
			if (this.projectsOpen) {
				let height = 0;
				for (let g of this.$el.querySelectorAll(".group-container")) {
					height += g.childNodes[0].offsetHeight + 10;
					if (g.classList.contains("open")) {
						height += g.childNodes[1].scrollHeight;
					}
				}
				this.subHeight = height;
			} else {
				this.subHeight = 0;
			}
		},
	},
	watch: {
		$route(e) {
			this.page = e.name;
			this.projectsOpen = e.name === "Project";
			for (let i = 0; i < this.sideInfo.groups.length; i++) {
				const id = this.sideInfo.groups[i].projects.findIndex(
					(p) => p.url === e.params.id
				);
				if (id > -1) {
					this.openGroup(this.sideInfo.groups[i].name);
				}
			}
			this.mobileMenuActive = false;
		},
		scroll(e) {
			if (this.page === "Main") {
				this.projectsOpen = e > window.innerHeight * 0.7;
			}
		},
		projectsOpen() {
			this.getProjectsHeight();
		},
	},
	mounted() {
		this.getProjects();
	},
	created() {
		this.getInstagram();
	},
};
</script>

<style lang="scss" scoped>
@import "./styles/config.scss";

section {
	position: fixed;
	left: ptr(30px);
	top: ptr(38px);
	display: grid;
	grid-template-rows: auto 1fr auto;
	height: calc(100vh - #{ptr(75px)});
	width: ptr(145px);
	z-index: 10;
	opacity: 0;
	animation: wakeUp 1s forwards;
}
@keyframes wakeUp {
	from {
		transform: translate3d(ptr(-100px), 0, 0);
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
.top-info {
	display: flex;
	justify-content: space-between;
	img {
		pointer-events: none;
		width: ptr(80px);
	}
}
.lang-selector {
	display: flex;
	gap: ptr(9px);
	height: fit-content;
}
.lang {
	border: none;
	background: none;
	padding: 0;
	font-size: ptr(12px);
	color: rgb(145, 145, 145);
	opacity: 0;
	&:nth-child(2) {
		animation-delay: 0.4s;
	}
}
.selected {
	color: white;
}
li {
	list-style: none;
	font-size: ptr(12px);
	transition: $trs;
	opacity: 0;
}
.projects-show {
	font-size: ptr(14px);
	text-transform: uppercase;
}
.page {
	transition: $trs;
	display: inline-block;
	padding-bottom: ptr(3px);
	&:hover {
		transform: translate3d(ptr(5px), 0, 0);
		color: white;
	}
}
.pages {
	display: grid;
	grid-auto-rows: min-content;
	padding-top: ptr(60px);
	@for $i from 1 through 30 {
		li:nth-child(#{$i}) {
			animation: minfadeUp 0.6s $anmTrs forwards;
			animation-delay: 0.6s + $i * 0.2s;
		}
	}
}
.sub-pages {
	transition: $trs;
	overflow: hidden;
	display: grid;
	grid-auto-rows: max-content;
	height: 0px;
	.page {
		color: rgb(255, 255, 255);
		font-weight: 500;
		font-size: ptr(13px);
		&::before {
			content: "→";
			position: absolute;
			left: ptr(-15px);
			opacity: 0;
			transition: $trs;
		}
	}
	.router-link-exact-active {
		transform: translate3d(ptr(15px), 0, 0);
		&::before {
			opacity: 1;
		}
	}
}
.show {
	opacity: 1;
	padding: ptr(14px) 0 ptr(14px);
}
a {
	color: $text-color;
	text-decoration: none;
	text-transform: uppercase;
	font-size: ptr(15px);
}
.inst {
	opacity: 0;
	animation: fadeLeft 0.8s 1.6s forwards;
	img {
		width: ptr(15px);
		position: relative;
		top: ptr(1px);
		animation: inRotate 0.8s 1.6s forwards;
	}
}

.mobile-active-btn,
.mobile-projects,
.mobile {
	display: none;
}

.groups {
	height: 0;
	transition: $trs;
	overflow: hidden;
}

.group-container {
	display: grid;
	transition: $trs;
}

.group-name {
	font-size: ptr(14px);
	text-transform: uppercase;
	font-weight: 700;
	display: flex;
	align-items: center;
	padding-bottom: ptr(3px);
	p {
		transition: 0.4s $anmTrs;
		color: #6c6c6c;
	}
	img {
		width: ptr(11px);
		transition: 0.4s $anmTrs;
		height: ptr(11px);
		margin-left: ptr(8px);
	}
}

.open {
	padding-bottom: ptr(20px);
	img {
		transform: rotate(-180deg);
	}
	li {
		opacity: 1;
	}
}

@media (max-width: 1024px) {
	section {
		left: ptr(15px);
		top: ptr(28px);
		height: unset;
		width: unset;
		position: absolute;
		display: flex;
		z-index: 100;
		justify-content: space-between;
		opacity: 1;
		animation: unset;
	}
	.inst,
	.desktop {
		display: none;
	}
	.mobile {
		display: flex;
		position: absolute;
		right: ptr(24px);
		bottom: ptr(55px);
		opacity: 0;
	}
	.desktop {
		display: none;
	}
	.pages {
		position: fixed;
		left: 0;
		top: 0;
		background: #000000;
		width: 100vw;
		height: unset;
		padding: ptr(0px) 0 ptr(0px) ptr(34px);
		display: grid;
		justify-content: flex-start;
		box-sizing: border-box;
		gap: ptr(10px);
		height: ptr(0px);
		opacity: 0;
		transition: padding 0.5s $anmTrs, height 0.3s $anmTrs,
			opacity 0.5s $anmTrs;
		pointer-events: none;
		overflow: hidden;
	}
	.page:hover {
		transform: unset;
	}
	.mobile-active-btn {
		display: flex;
		width: ptr(48px);
		height: ptr(30px);
		position: fixed;
		right: ptr(28px);
		z-index: 2;
		&::after,
		&::before {
			content: "";
			width: ptr(48px);
			height: ptr(2px);
			background: white;
			position: absolute;
			z-index: 2;
			transition: top 0.2s $anmTrs, transform 0.5s $anmTrs;
		}
		&::before {
			top: ptr(10px);
		}
		&::after {
			top: ptr(20px);
		}
	}
	.close-btn {
		&::before {
			top: ptr(15px);
			transform: rotate(45deg);
		}
		&::after {
			top: ptr(15px);
			transform: rotate(-45deg);
		}
	}
	.active {
		opacity: 1;
		padding: ptr(30px) 0 ptr(120px) ptr(34px);
		pointer-events: all;
		height: 100%;
		.mobile {
			opacity: 1;
		}
	}
	.mobile-projects {
		display: grid;
		padding-top: ptr(24px);
		margin-top: ptr(50px);
		gap: ptr(10px);
		position: relative;
		&::before {
			content: "";
			width: ptr(317px);
			height: 1px;
			position: absolute;
			top: 0;
			background: #262626;
		}
		.router-link-exact-active {
			color: rgba(217, 217, 217, 1);
		}
	}
	.mob-project {
		font-size: ptr(14px);
		color: rgba(217, 217, 217, 0.62);
	}
	.groups {
		display: none;
	}
	a {
		font-size: ptr(24px);
		&:hover {
			transform: unset;
			color: $text-color;
		}
	}
	.top-info {
		img {
			width: ptr(70px);
		}
	}
}
</style>
