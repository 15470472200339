<template>
	<div class="block-container">
		<sticker
			class="sticker"
			:scroll="paralaxScrl"
			:data="data.sticker"
		></sticker>
		<div class="grid-container">
			<div
				v-for="(n, k) in data.numbers"
				:key="k"
				:ref="'number' + k"
				:class="`n-${k} number-block`"
			>
				<div class="number">
					<span v-for="(n, k) in n.title" :key="k">
						{{ n }}
					</span>
				</div>
				<p class="desc">
					<span>{{ n.text }}</span>
				</p>
			</div>
		</div>
	</div>
</template>

<script>
import anmMixin from "@/mixins/anmMixin";
import Sticker from "../Sticker.vue";
export default {
	components: { Sticker },
	mixins: [anmMixin],
	props: {
		data: {
			default: () => ({
				numbers: [],
				sticker: {
					color: "",
					text: "",
					url: "",
				},
			}),
		},
		scroll: {
			default: 0,
		},
	},
	data() {
		return {
			paralaxScrl: 0,
		};
	},
	watch: {
		scroll() {
			const rect = this.$el.getBoundingClientRect();
			if (rect.top - window.innerHeight < 0 && rect.bottom > 0) {
				this.paralaxScrl = rect.top - window.innerHeight;
			}
		},
		data() {
			setTimeout(() => {
				const elements = [];
				let ch = 0;
				for (let r in this.$refs) {
					elements.push({
						name: "." + this.$refs[r].classList[0],
						id: ch,
						animationName: "fadeNumbers",
						distance: 200,
						delay: 200,
					});
					ch++;
				}
				this.setObserverFunction(elements);
			}, 0);
		},
	},
	mounted() {
		const elements = [];
		let ch = 0;
		for (let r in this.$refs) {
			elements.push({
				name: "." + this.$refs[r].classList[0],
				id: ch,
				animationName: "fadeNumbers",
				distance: 200,
				delay: 200,
			});
			ch++;
		}
		elements.push({
			name: ".paralax-container",
			id: "sticker",
			animationName: "fade",
			distance: 200,
			delay: 100,
		});
		this.setObserverFunction(elements);
	},
};
</script>

<style lang="scss" scoped>
@import "../styles/blockStyles.scss";
@import "../styles/config.scss";

.block-container {
	padding-left: ptr(461px);
}

.sticker {
	top: ptr(500px);
	left: ptr(470px);
}

.grid-container {
	display: grid;
	gap: ptr(150px) ptr(90px);
	height: 100%;
	grid-template-columns: repeat(3, ptr(400px));
	grid-template-rows: ptr(226px);
}
.number {
	font-size: ptr(143px);
	line-height: ptr(140px);
	font-weight: 300;
	margin-bottom: ptr(35px);
	overflow: hidden;
	span {
		opacity: 0;
		display: inline-block;
		animation-duration: 0.6s;
		animation-timing-function: $anmTrs;
		animation-fill-mode: forwards;
	}
}
.desc {
	font-size: ptr(16px);
	line-height: ptr(25px);
	span {
		display: inline-block;
		opacity: 0;
		transition: 1.5s $anmTrs;
		white-space: pre-line;
		transform: translate3d(0, ptr(70px), 0) skewY(4deg);
	}
}
.number-block:nth-child(1) {
	grid-column: 2;
	grid-row: 1;
}
.number-block:nth-child(2) {
	grid-column: 1;
	grid-row: 2;
}
.number-block:nth-child(3) {
	grid-column: 2;
	grid-row: 2;
}
.number-block:nth-child(4) {
	grid-column: 2;
	grid-row: 3;
}
.number-block:nth-child(5) {
	grid-column: 3;
	grid-row: 4;
}

.fadeNumbers {
	.number {
		@for $i from 1 through 40 {
			span:nth-child(#{$i}) {
				animation: spanUp 0.65s $anmTrs forwards;
				animation-delay: $i * 0.05s;
			}
		}
	}
	.desc span {
		opacity: 1;
		transform: translate3d(0, ptr(0px), 0) skewY(0deg);
	}
}

@keyframes spanUp {
	from {
		opacity: 0;
		transform: translate3d(0, ptr(55px), 0);
	}
	to {
		opacity: 1;
	}
}

@media (max-width: 1024px) {
	.block-container {
		padding-left: ptr(15px);
	}
	.grid-container {
		gap: ptr(53px);
		grid-template-columns: 1fr;
		width: calc(100vw - #{ptr(75px)});
		grid-template-rows: ptr(156px);
	}
	.number {
		font-size: ptr(100px);
		margin-bottom: ptr(3px);
	}
	.desc {
		font-size: ptr(14px);
		width: ptr(200px);
		line-height: ptr(17px);
	}
	.number-block:nth-child(1) {
		grid-column: 1;
	}
	.number-block:nth-child(2) {
		justify-self: flex-end;
	}
	.number-block:nth-child(3) {
		grid-column: 1;
		grid-row: 3;
	}
	.number-block:nth-child(4) {
		grid-column: 1;
		grid-row: 4;
		justify-self: flex-end;
	}
	.number-block:nth-child(5) {
		grid-column: 1;
		grid-row: 5;
	}
}
</style>
