<template>
	<a
		:href="data.url"
		@mouseleave="leaveCircle"
		@mouseenter="checkCircle($event)"
		@mousemove="getPos"
		class="media-block"
		:style="
			`transform: ${hover ? 'scale(.95)' : ''} translate3d(${-circle.x /
				2.5}px,${-circle.y / 2.5}px,0)`
		"
	>
		<img
			:src="data.icon"
			:style="`transform: translate3d(${-circle.x}px,${-circle.y}px,0)`"
			alt="Иконка СМИ"
		/>
	</a>
</template>

<script>
import mouseFollowMixin from "@/mixins/mouseFollowMixin";
export default {
	mixins: [mouseFollowMixin],
	props: {
		data: {
			default: {},
		},
	},
};
</script>

<style lang="scss" scoped>
@import "../../styles/config.scss";

.media-block {
	border: 2px solid #979797;
	border-radius: 50%;
	width: ptr(307px);
	height: ptr(307px);
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 0;
	animation-duration: 0.6s;
	animation-timing-function: $anmTrs;
	animation-fill-mode: forwards;
	transition: 0.6s ease-out;
	&:hover {
		will-change: transform;
		transform: scale(0.95);
		img {
			will-change: transform;
			transition: none;
		}
	}
	img {
		pointer-events: none;
		position: relative;
		top: ptr(5px);
		height: ptr(120px);
		width: ptr(110px);
		object-fit: contain;
	}
}
.media-block:nth-child(1) {
	grid-column: 3;
	animation-delay: 0.6s;
}
.media-block:nth-child(2) {
	grid-column: 4;
	animation-delay: 0.3s;
}
.media-block:nth-child(3) {
	grid-column: 3;
	grid-row: 2;
}
.media-block:nth-child(4) {
	grid-column: 1;
	grid-row: 3;
	animation-delay: 0.9s;
}
.media-block:nth-child(5) {
	grid-column: 2;
	grid-row: 3;
	animation-delay: 1.2s;
}
.media-block:nth-child(6) {
	grid-column: 3;
	grid-row: 4;
	animation-delay: 1.5s;
}
.fadeNumbers .media-block {
	animation-name: fade;
}
@media (max-width: 1024px) {
	.media-block {
		width: ptr(162px);
		height: ptr(162px);
		img {
			width: ptr(60px);
			height: ptr(60px);
			top: ptr(2px);
		}
	}
	.media-block:nth-child(1) {
		grid-column: 1;
	}
	.media-block:nth-child(2) {
		grid-column: 2;
	}
	.media-block:nth-child(3) {
		grid-column: 1;
	}
	.media-block:nth-child(4) {
		grid-column: 2;
		grid-row: 2;
	}
	.media-block:nth-child(5) {
		grid-column: 1;
		grid-row: 3;
	}
	.media-block:nth-child(6) {
		grid-column: 2;
	}
}
</style>
