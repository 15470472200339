<template>
	<section></section>
</template>

<script>
export default {
	mounted() {
		window.location =
			"https://docs.google.com/forms/d/e/1FAIpQLSfDmYvidL71ededChMD9eZsD9BY1LrO7nnrDoHtoNliLdoUXQ/viewform";
	},
};
</script>

<style></style>
