<template>
	<section>
		<article class="main">
			<video
				preload="auto"
				autoplay
				loop
				muted
				playsinline
				class="back-video"
				src="/static/video/comp1.mp4"
			></video>
			<div class="h-text">
				<h1 class="fadeSpanList">
					<template v-for="(p, k) in 'We are '">
						<span v-if="mobile" :key="k" class="l">
							{{ p }}
						</span>
						<br v-if="mobile && k === 6" :key="k" />
					</template>
					<span v-for="(p, k) in 'Placebo'" :key="k" class="l">
						{{ p }}
					</span>
					<img src="/static/icons/line.svg" alt="/" />
					<span v-for="(n, k) in '25'" class="l" :key="k">{{
						n
					}}</span>
					<span class="r">®</span>
				</h1>
				<div class="add-text">
					<p>
						{{ $t("topTxt[0]") }}
						<br v-if="!mobile" />
						<router-link to="/about">
							{{ $t("topTxt[1]") }}
						</router-link>
					</p>
					<!-- <p>
						{{ $t("topTxt[2]") }}
					</p> -->
				</div>
			</div>
		</article>
		<img
			src="/static/icons/arrow.svg"
			class="arrow-down"
			alt="Стрелка переправляющая на проекты компании"
			@click="moveDown"
		/>
		<article class="projects" id="projects">
			<!-- <img src="/static/img/backImg.png" alt="" class="back-img" /> -->
			<div class="head-block">
				<h2>
					<span v-for="(p, k) in $t('pages.projects')" :key="k">
						{{ p }}
					</span>
					<p class="n">({{ projects.length }})</p>
				</h2>
				<p class="sub-p">
					Placebo/25 Inspiration<br class="desc" />
					System
				</p>
			</div>
			<div class="content">
				<description
					:scroll="scroll"
					:data="{ ...descData, est: projects.length + ' Products' }"
				/>
				<h3>
					<span>
						LIST OF
					</span>
					<br />
					<span>
						PROJECTS
					</span>
				</h3>
				<ul class="list">
					<project-component
						v-for="(p, k) in projects"
						:key="k"
						:data="p"
					/>
				</ul>
				<router-link to="/Contact/#emails" class="cooperate HOVER">
					<span class="about"></span>
					<p>+ {{ $t("coop") }}</p>
				</router-link>
				<div class="infinite-line">
					<div
						class="move-line"
						:style="`transform: translate3d(${-lineTrs}px,0,0)`"
					>
						<div
							class="logo-circe"
							v-for="(p, k) in infiniteLineProjects"
							:key="k"
						>
							<img :src="p.logo" alt="лого" />
						</div>
						<div
							class="logo-circe"
							v-for="(p, k) in infiniteLineProjects"
							:key="k"
						>
							<img :src="p.logo" alt="лого" />
						</div>
					</div>
				</div>
			</div>
			<router-link to="/about" class="next">
				NEXT →
			</router-link>
			<Footer />
		</article>
	</section>
</template>

<script>
import Description from "../components/Blocks/Description.vue";
import ProjectComponent from "../components/ProjectComponent.vue";
import anmMixin from "@/mixins/anmMixin";
import Footer from "../components/Blocks/Footer.vue";
import { http } from "../components/Services/httpService";

export default {
	mixins: [anmMixin],
	components: { Description, ProjectComponent, Footer },
	props: {
		scroll: {
			default: 0,
		},
		mobile: {
			default: false,
		},
	},
	data() {
		return {
			lineTrs: 305,
			mainScreen: {
				move: false,
				height: 0,
				down: true,
			},
			descData: {
				title: this.$t("ecosystem.htext"),
				content: this.$t("ecosystem.description"),
				est: "12 Products",
				sticker: {
					color: "#496066",
					text: "Filled with meaning & atmosphere",
					url: "",
				},
			},
			projects: [
				// {
				// 	type: "Event Spaces",
				// 	name: "LOFT HALL",
				// 	source: "www.cutok.ru",
				// 	description:
				// 		"За 5 лет компания стала ключевой и универсальной платформой для проведения мероприятий всевозможных форматов. Первоклассное обслуживание",
				// 	url: "string",
				// 	siteUrl: "string",
				// 	logo: "/static/icons/1 (4).svg",
				// 	inProgress: false,
				// },
				// {
				// 	type: "International lifestyle hotels",
				// 	name: "LOFT'EL",
				// 	source: "www.cutok.ru",
				// 	description:
				// 		"За 5 лет компания стала ключевой и универсальной платформой для проведения мероприятий всевозможных форматов. Первоклассное обслуживание",
				// 	url: "string",
				// 	siteUrl: "string",
				// 	logo: "/static/icons/1 (6).svg",
				// 	inProgress: false,
				// },
				// {
				// 	type: "Major concert hall",
				// 	name: "TEMPLE OF DEER",
				// 	source: "www.cutok.ru",
				// 	description:
				// 		"За 5 лет компания стала ключевой и универсальной платформой для проведения мероприятий всевозможных форматов. Первоклассное обслуживание",
				// 	url: "string",
				// 	siteUrl: "string",
				// 	logo: "/static/icons/1 (6).svg",
				// 	inProgress: false,
				// },
				// {
				// 	type: "Boutique barbershop",
				// 	name: "CLIQUE",
				// 	source: "www.cutok.ru",
				// 	description:
				// 		"За 5 лет компания стала ключевой и универсальной платформой для проведения мероприятий всевозможных форматов. Первоклассное обслуживание",
				// 	url: "string",
				// 	siteUrl: "string",
				// 	logo: "/static/icons/1 (2).svg",
				// 	inProgress: true,
				// },
				// {
				// 	type: "Iconic nightclub",
				// 	name: "BROKEN HEARTS CLUB",
				// 	source: "www.cutok.ru",
				// 	description:
				// 		"За 5 лет компания стала ключевой и универсальной платформой для проведения мероприятий всевозможных форматов. Первоклассное обслуживание",
				// 	url: "string",
				// 	siteUrl: "string",
				// 	logo: "/static/icons/1 (6).svg",
				// 	inProgress: false,
				// },
				// {
				// 	type: "Designer restaurant & bar",
				// 	name: "MR.PINKY",
				// 	source: "www.cutok.ru",
				// 	description:
				// 		"За 5 лет компания стала ключевой и универсальной платформой для проведения мероприятий всевозможных форматов. Первоклассное обслуживание",
				// 	url: "string",
				// 	siteUrl: "string",
				// 	logo: "/static/icons/1 (3).svg",
				// 	inProgress: false,
				// },
				// {
				// 	type: "Boutique barbershop",
				// 	name: "CUT-OK",
				// 	source: "www.cutok.ru",
				// 	description:
				// 		"За 5 лет компания стала ключевой и универсальной платформой для проведения мероприятий всевозможных форматов. Первоклассное обслуживание",
				// 	url: "string",
				// 	siteUrl: "string",
				// 	logo: "/static/icons/1 (1).svg",
				// 	inProgress: false,
				// },
				// {
				// 	type: "Sound culture",
				// 	name: "MEDUZA",
				// 	source: "www.cutok.ru",
				// 	description:
				// 		"За 5 лет компания стала ключевой и универсальной платформой для проведения мероприятий всевозможных форматов. Первоклассное обслуживание",
				// 	url: "string",
				// 	siteUrl: "string",
				// 	logo: "/static/icons/1 (6).svg",
				// 	inProgress: false,
				// },
				// {
				// 	type: "Equipment Rental",
				// 	name: "MAGNITOFON",
				// 	source: "www.cutok.ru",
				// 	description:
				// 		"За 5 лет компания стала ключевой и универсальной платформой для проведения мероприятий всевозможных форматов. Первоклассное обслуживание",
				// 	url: "string",
				// 	siteUrl: "string",
				// 	logo: "/static/icons/1 (6).svg",
				// 	inProgress: false,
				// },
				// {
				// 	type: "Craft furniture",
				// 	name: "ASKETI.CA",
				// 	source: "www.cutok.ru",
				// 	description:
				// 		"За 5 лет компания стала ключевой и универсальной платформой для проведения мероприятий всевозможных форматов. Первоклассное обслуживание",
				// 	url: "string",
				// 	siteUrl: "string",
				// 	logo: "/static/icons/1 (5).svg",
				// 	inProgress: false,
				// },
				// {
				// 	type: "Architecture & construction bureau",
				// 	name: "DEVELOPMENT",
				// 	source: "www.cutok.ru",
				// 	description:
				// 		"За 5 лет компания стала ключевой и универсальной платформой для проведения мероприятий всевозможных форматов. Первоклассное обслуживание",
				// 	url: "string",
				// 	siteUrl: "string",
				// 	logo: "/static/icons/1 (6).svg",
				// 	inProgress: false,
				// },
				// {
				// 	type: "Concerts & show agency",
				// 	name: "ENTERTAINMENT",
				// 	source: "www.cutok.ru",
				// 	description:
				// 		"За 5 лет компания стала ключевой и универсальной платформой для проведения мероприятий всевозможных форматов. Первоклассное обслуживание",
				// 	url: "string",
				// 	siteUrl: "string",
				// 	logo: "/static/icons/1 (6).svg",
				// 	inProgress: false,
				// },
			],
		};
	},
	computed: {
		infiniteLineProjects() {
			return this.projects.filter((p) => !!p.logo);
		},
	},
	methods: {
		scrollToScreen() {
			const { down, move, height } = this.mainScreen;
			if (!move || this.$route.name !== "Main") {
				return;
			}
			const max = down ? height : 0;
			// const mod =
			// 	(max ? height - window.scrollY * 0.95 : window.scrollY * 0.95) /
			// 	30000;
			// const mod = (height / 2 - Math.abs(window.scrollY - height / 2)) / 8000;
			const mod = Math.abs(height - window.scrollY) / 15000;
			let h = height * mod * (down ? 1 : -1) + 1;
			// h = Math.abs(h) < 1 ? (down ? 1 : -1) : Math.floor(h);
			window.scrollTo({
				top: window.scrollY + h,
			});
			if (window.scrollY === 0 || window.scrollY >= height) {
				this.mainScreen.move = false;
				window.scrollTo({
					top: max,
				});
			}
			requestAnimationFrame(this.scrollToScreen);
		},
		getProjects() {
			http.get(`/api/projects?lang=${this.$i18n.locale}`).then(
				(response) => {
					this.projects = response.data.projects;
					this.setObserverFunction([
						{
							name: ".list",
							animationName: "fadePrj",
							distance: 300,
						},
					]);
					setTimeout(() => {
						this.setProjectsLine();
					}, 0);
				}
			);
		},
		setProjectsLine() {
			const el = this.$el.querySelector(".move-line");
			const mobile = window.innerWidth;
			const moveLine = () => {
				setTimeout(
					() => {
						if (this.lineTrs >= el.scrollWidth) {
							this.lineTrs = 0;
						} else {
							this.lineTrs += 0.5;
						}
						requestAnimationFrame(moveLine);
					},
					mobile ? 30 : 1
				);
			};
			moveLine();
		},
		moveDown() {
			this.mainScreen.move = true;
			this.mainScreen.down = true;
			this.scrollToScreen();
		},
	},
	watch: {
		"$route.hash"(newVal) {
			if (this.$route.path !== "/") {
				return;
			}

			if (newVal.length > 0) {
				this.$el.querySelector(newVal).scrollIntoView();
			} else {
				window.scrollTo(0, 0);
			}
		},
		scroll(newVal, prevVal) {
			// if (newVal < window.innerHeight) {
			// 	this.$el
			// 		.querySelector(".back-img")
			// 		.classList.remove("fade-img");
			// }
			// const isDesktop = window.innerWidth > 1024;
			// if (newVal <= this.mainScreen.height && isDesktop) {
			// 	if (!this.mainScreen.move) {
			// 		this.mainScreen.move = true;
			// 		this.mainScreen.down = newVal - prevVal > 0;
			// 		this.scrollToScreen();
			// }
			if (this.mainScreen.down !== newVal - prevVal > 0) {
				this.mainScreen.move = false;
			}
			this.smoothScrlAnm(".back-video", "opacity", 1 - newVal * 0.002);
			this.smoothScrlAnm(".h-text", "opacity", 1 - newVal * 0.003);
			this.smoothScrlAnm(".arrow-down", "opacity", 1 - newVal * 0.003);
			this.smoothScrlAnm(
				".h-text",
				"transform",
				`scale(${1 - newVal * 0.0003})`
			);
		},
	},
	mounted() {
		this.mainScreen.height = window.innerHeight * 0.8;
		window.scrollTo(0, 0);
		setTimeout(() => {
			if (this.$route.hash.length > 0) {
				this.$el.querySelector(this.$route.hash).scrollIntoView();
				this.smoothScrlAnm(".back-video", "opacity", 0);
				this.smoothScrlAnm(".h-text", "opacity", 0);
				this.smoothScrlAnm(".arrow-down", "opacity", 0);
				this.smoothScrlAnm(".h-text", "transform", `scale(${0})`);
			}
			this.setObserverFunction([
				{
					name: "h2",
					animationName: "fadeSpanList",
					distance: 300,
				},
				{
					name: ".sub-p",
					animationName: "minfadeUp",
					distance: 300,
					delay: 600,
				},
				{
					name: "h3",
					animationName: "sideWords",
					distance: 300,
				},
			]);
		}, 10);
		document.title = "Placebo/25";
	},
	created() {
		this.getProjects();
	},
};
</script>

<style lang="scss" scoped>
@import "../components/styles/config.scss";

.main {
	height: 100vh;
	overflow: hidden;
	position: sticky;
	top: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.arrow-down {
	position: fixed;
	right: ptr(54px);
	bottom: ptr(35px);
	z-index: 3;
}

p {
	margin: 0;
}

.back-video {
	width: 100%;
	position: absolute;
	z-index: -1;
	height: 100vh;
	object-fit: cover;
}

.h-text {
	position: relative;
	top: ptr(0px);
}

h1 {
	font-size: ptr(70px);
	text-transform: uppercase;
	font-weight: 500;
	line-height: ptr(60px);
	margin: 0;
	white-space: break-spaces;
	overflow: hidden;
	height: fit-content;

	span {
		opacity: 0;
		display: inline-block;
	}

	.l {
		color: #d5daec;
	}

	img {
		width: ptr(36px);
		opacity: 0;
	}

	.r {
		display: inline-block;
		font-size: ptr(37px);
		top: ptr(10px);
		transform: translate3d(ptr(5px), ptr(-42px), 0);
		animation: fade $timing $anmTrs forwards;
		animation-delay: 1.3s;
		position: absolute;
	}
}

.line {
	width: ptr(25px);
}

.add-text {
	position: absolute;
	bottom: ptr(15px);
	right: ptr(-563px);
	width: ptr(478px);
	text-transform: uppercase;
	font-size: ptr(12px);
	line-height: ptr(23px);

	p {
		display: inline-block;
		opacity: 0;
	}

	a {
		color: $text-color;
		text-decoration: none;

		&::after {
			content: "→";
			opacity: 0;
			margin-left: ptr(4px);
			transition: $trs;
		}

		&:hover::after {
			opacity: 1;
		}
	}

	@for $i from 1 through 20 {
		p:nth-child(#{$i}) {
			animation: minfadeUp $timing $anmTrs forwards;
			animation-delay: 1.4s + $i * 0.05s;
		}
	}
}

.projects {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: relative;
	margin-top: -20vh;
	z-index: 2;
}

.back-img {
	position: absolute;
	top: 0;
	opacity: 0;
	width: 100%;
	transition: $trs;
}

.fade-img {
	opacity: 1;
}

.head-block {
	height: 100vh;
	pointer-events: none;
	display: flex;
	justify-self: center;
	align-items: center;
	position: relative;
}

h2 {
	margin: 0;
	position: relative;
	left: ptr(32px);
	top: ptr(-50px);
	font-size: ptr(70px);
	text-transform: uppercase;
	line-height: ptr(60px);
	padding: ptr(20px) ptr(65px) 0 0;

	span {
		opacity: 0;
		display: inline-block;
		background: linear-gradient(#dbbbbb, #d8d8d8);
		background-clip: text;
		color: transparent;
	}

	font-weight: 500;
}

.n {
	position: absolute;
	display: inline-block;
	font-size: ptr(27px);
	opacity: 0;
	top: ptr(32px);
	background: linear-gradient(#dbbbbb, #d8d8d8);
	background-clip: text;
	color: transparent;
}

.fadeSpanList .n {
	animation: topN $timing $anmTrs forwards;
	animation-delay: 0.63s;
}

@keyframes topN {
	from {
		opacity: 0;
		transform: translate3d(ptr(7px), ptr(-30px), 0);
	}
	to {
		opacity: 1;
		transform: translate3d(ptr(7px), ptr(-50px), 0);
	}
}

.sub-p {
	position: absolute;
	bottom: ptr(250px);
	text-transform: uppercase;
	right: ptr(-51px);
	width: ptr(250px);
	line-height: ptr(23px);
	opacity: 0;
}

.content {
	width: 100%;
	box-sizing: border-box;
	display: grid;
	padding-bottom: ptr(387px);
	overflow: hidden;
}

h3 {
	font-size: ptr(60px);
	font-weight: 300;
	line-height: ptr(66px);
	margin: ptr(282px) 0 ptr(171px) 0;
	position: relative;
	justify-self: center;
	right: ptr(-157px);

	span {
		opacity: 0;
	}

	span:nth-child(3) {
		margin-left: ptr(60px);
	}
}

.list {
	width: 100%;
	padding-left: ptr(287px);
	box-sizing: border-box;
}

.fadePrj {
	@for $i from 1 through 20 {
		li:nth-child(#{$i}) {
			animation: fadeUp 0.6s $anmTrs forwards;
			animation-delay: $i * 0.2s;

			&::before {
				transition: 1.2s
					$i *
					0.2s +
					0.04s
					cubic-bezier(0.25, 0.56, 0.45, 0.98);
				width: 100%;
			}
		}
	}
}

.cooperate {
	padding: ptr(10px) ptr(32px) ptr(10px) ptr(26px);
	border: 2px solid rgba(255, 255, 255, 0.35);
	border-radius: ptr(100px);
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
	border-radius: ptr(100px);
	font-size: ptr(16px);
	text-transform: uppercase;
	width: fit-content;
	margin: ptr(150px) 0 ptr(348px);
	position: relative;
	height: ptr(41px);
	font-weight: 300;
	box-sizing: border-box;
	left: ptr(951px);
	text-decoration: none;

	p {
		transition: $trs;
		pointer-events: none;
		position: relative;
		z-index: 2;
	}
}

.infinite-line {
	display: flex;
	width: 100vw;
}

.move-line {
	display: flex;
}

.logo-circe {
	width: ptr(305px);
	height: ptr(305px);
	border: 2px solid #404040;
	border-radius: 50%;
	flex-shrink: 0;
	display: flex;
	align-items: center;
	justify-content: center;

	img {
		width: ptr(150px);
		height: ptr(100px);
		object-fit: contain;
	}
}

@media (max-width: 1024px) {
	.content {
		padding-bottom: ptr(27px);
	}
	.back-img {
		display: none;
	}
	.main {
		align-items: flex-start;
	}
	.desc {
		display: none;
	}
	.h-text {
		// padding: ptr(245px) 0 0 ptr(15px);
		top: ptr(-70px);
		left: ptr(15px);
	}
	h1 {
		font-size: ptr(46px);
		line-height: ptr(45px);
		img {
			width: ptr(23px);
			animation-delay: 1.1s;
		}

		.r {
			font-size: ptr(35px);
			right: ptr(-20px);
			display: none;
			transform: translate3d(ptr(6px), ptr(25px), 0);
		}
	}
	.arrow-down {
		display: none;
	}
	.back-video {
		height: 100%;
		width: unset;
	}
	.main {
		&::before {
			content: "";
			position: absolute;
			width: 100%;
			height: 50%;
			bottom: 0;
			background: linear-gradient(rgba(0, 0, 0, 0), black);
		}
	}
	.projects {
		margin-top: -10vh;
	}
	.add-text {
		font-size: ptr(10px);
		line-height: ptr(16px);
		right: unset;
		left: ptr(4px);
		width: ptr(320px);
		bottom: ptr(-50px);
	}
	.head-block {
		height: 30vh;
		width: calc(100vw - #{ptr(15px)});
		padding-bottom: ptr(40px);
		margin: 0 0 ptr(35px) ptr(15px);
		border-bottom: 1px solid #464646;
		box-sizing: border-box;
		justify-content: flex-start;
	}
	h2 {
		left: ptr(0px);
		font-size: ptr(45px);
		font-weight: 500;
	}
	.n {
		font-size: ptr(16px);
		right: ptr(30px);
	}
	.sub-p {
		bottom: unset;
		top: ptr(100px);
		width: ptr(300px);
		font-size: ptr(12px);
		right: unset;
	}
	h3 {
		font-size: ptr(45px);
		font-weight: 500;
		line-height: ptr(48px);
		margin: ptr(140px) 0 ptr(67px) 0;
		right: 0;
		justify-self: flex-start;
		padding-left: ptr(15px);

		span:nth-child(3) {
			margin-left: ptr(0px);
		}
	}
	.list {
		padding-left: ptr(15px);
	}
	.cooperate {
		font-size: ptr(14px);
		margin: ptr(60px) 0;
		right: unset;
		left: unset;
		justify-self: center;
	}
	.infinite-line {
		grid-row: 2;
		margin-top: ptr(131px);
	}
	.logo-circe {
		width: ptr(118px);
		height: ptr(118px);

		img {
			width: ptr(50px);
			height: ptr(50px);
			object-fit: contain;
		}
	}
	@keyframes topN {
		from {
			opacity: 0;
			transform: translate3d(ptr(3px), ptr(0px), 0);
		}
		to {
			opacity: 1;
			transform: translate3d(ptr(3px), ptr(-30px), 0);
		}
	}
}
</style>
