<template>
	<div class="block-container">
		<h2>
			<span>
				{{ $t("MM[0]") }}
			</span>
			<br />
			<span>
				{{ $t("MM[1]") }}
			</span>
		</h2>
		<div class="grid-container">
			<Circle v-for="(n, k) in data.news" :key="k" :data="n" />
		</div>
	</div>
</template>

<script>
import anmMixin from "@/mixins/anmMixin";
import Circle from "./Circle.vue";
export default {
	mixins: [anmMixin],
	components: { Circle },
	props: {
		data: {
			default: () => ({
				news: [],
			}),
		},
	},
	mounted() {
		this.setObserverFunction([
			{
				name: "h2",
				animationName: "sideWords",
			},
			{
				name: ".grid-container",
				animationName: "fadeNumbers",
			},
		]);
	},
};
</script>

<style lang="scss" scoped>
@import "../../styles/blockStyles.scss";

.block-container {
	padding-left: ptr(332px);
}
h2 {
	font-size: ptr(60px);
	font-weight: 300;
	line-height: ptr(66px);
	position: relative;
	left: ptr(615px);
	width: fit-content;
	span {
		opacity: 0;
	}
	span:nth-child(3) {
		margin-left: ptr(60px);
	}
	margin: 0 0 ptr(150px);
}

.grid-container {
	display: grid;
	gap: ptr(3px);
	grid-template-columns: repeat(3, ptr(307px));
	grid-template-rows: ptr(307px);
}

@media (max-width: 1024px) {
	.block-container {
		padding-left: ptr(15px);
	}
	h2 {
		font-size: ptr(45px);
		font-weight: 500;
		line-height: ptr(41px);
		left: ptr(0px);
		margin: 0 0 ptr(75px);
	}
	.grid-container {
		grid-template-columns: repeat(2, ptr(162px));
		grid-template-rows: ptr(162px);
	}
}
</style>
