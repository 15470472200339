<template>
	<section>
		<div class="header">
			<div class="head-container">
				<h1 class="fadeSpanList">
					<span v-for="(l, k) in $t('pages.contacts')" :key="k">
						{{ l }}
					</span>
				</h1>
				<div class="sub-text minfadeUp">
					{{ contactData.description }}
				</div>
			</div>
		</div>
		<div class="map-container">
			<h2>
				ON THE MAP
			</h2>
			<Map :data="blocksData[2]" :onlyWorld="true" />
		</div>
		<!-- <press-kit :data="blocksData[1]" /> -->
		<emails-blocks id="emails" :data="blocksData[0]" />
		<Footer />
	</section>
</template>

<script>
import Footer from "../components/Blocks/Footer.vue";
// import PressKit from "../components/Blocks/PressKit.vue";
import anmMixin from "@/mixins/anmMixin";
import { http } from "../components/Services/httpService";
import EmailsBlocks from "../components/Blocks/EmailsBlocks.vue";
import Map from "../components/Blocks/Map.vue";
export default {
	mixins: [anmMixin],
	components: { Footer, EmailsBlocks, Map },
	data() {
		return {
			contactData: {
				blocks: [],
				description: "",
			},
		};
	},
	methods: {
		getContacts() {
			http.get(`/api/contacts?lang=${this.$i18n.locale}`).then(
				(response) => {
					this.contactData = response.data;
				}
			);
		},
	},
	computed: {
		blocksData() {
			const blocks = [];
			for (let e of this.contactData.blocks) {
				blocks.push(e.id ? e.data : {});
			}
			return blocks;
		},
	},
	created() {
		this.getContacts();
	},
	mounted() {
		this.setObserverFunction([
			{
				name: "h2",
				animationName: "fadeLeft",
			},
		]);
		window.scrollTo(0, 0);
		document.title = "P/25 | Contacts";

		if (this.$route.hash.length > 0) {
			this.$el.querySelector(this.$route.hash).scrollIntoView(true);
		}
	},
};
</script>

<style lang="scss" scoped>
@import "../components/styles/config.scss";

.header {
	width: 100%;
	height: 100vh;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.head-container {
	position: relative;
}

h1 {
	font-size: ptr(70px);
	text-transform: uppercase;
	line-height: ptr(60px);
	font-weight: 500;
	overflow: hidden;
	margin: 0;
	span {
		opacity: 0;
		display: inline-block;
	}
	@for $i from 1 through 20 {
		span:nth-child(#{$i}) {
			background: linear-gradient(
				0.12turn,
				#ffeede 0%,
				#d8d8d8 100% - $i * 5
			);
			-webkit-text-fill-color: transparent;
			background-clip: text;
		}
	}
}

.sub-text {
	position: absolute;
	width: ptr(250px);
	height: ptr(400px);
	left: ptr(190px);
	top: ptr(180px);
	line-height: ptr(20px);
	font-size: ptr(14px);
	opacity: 0;
	animation-delay: 0.5s;
	text-transform: uppercase;
}

.map-container {
	position: relative;
	margin: ptr(40px) 0 ptr(300px);
}

h2 {
	position: relative;
	margin: 0;
	opacity: 0;
	font-size: ptr(60px);
	font-weight: 300;
	width: fit-content;
	margin-bottom: ptr(127px);
	left: ptr(950px);
}

.map {
	opacity: 0;
}

.map img {
	width: ptr(1390px);
}

.point {
	background-image: url("/static/icons/point.svg");
	background-size: contain;
	width: ptr(39px);
	height: ptr(60px);
	transform: translate3d(ptr(770px), ptr(200px), 0);
}

@media (max-width: 1024px) {
	.header {
		align-items: flex-start;
		transform: translateY(ptr(-20px));
	}

	h1 {
		font-size: ptr(46px);
		line-height: ptr(45px);
		position: relative;
		left: ptr(15px);
	}

	.head-container {
		top: ptr(-60px);
	}

	.sub-text {
		width: ptr(300px);
		left: ptr(18px);
		right: unset;
		top: ptr(60px);
		height: unset;
		line-height: ptr(18px);
		font-size: ptr(10px);
	}

	.map-container {
		gap: ptr(76px);
		width: 100vw;
		position: relative;
		overflow: hidden;
		margin: ptr(20px) 0 ptr(80px);
	}

	h2 {
		font-size: ptr(45px);
		font-weight: 500;
		left: ptr(15px);
	}

	.map {
		opacity: 0;
		width: 100vw;
	}

	.map img {
		width: ptr(781px);
		position: relative;
		left: ptr(-230px);
	}
}
</style>
