<template>
	<div class="block-container">
		<div class="top-txt">
			<span v-for="(p, k) in infoData.title" :key="k">
				{{ p }}
			</span>
		</div>
		<div class="bottom-txt">
			<p class="add-txt">
				<span v-for="(p, k) in infoData.content" :key="k">
					{{ p }}
				</span>
			</p>
		</div>
	</div>
</template>

<script>
import anmMixin from "@/mixins/anmMixin";
export default {
	mixins: [anmMixin],
	props: {
		data: {
			default: () => ({
				title: "",
				content: "",
			}),
		},
		topTxt: {
			default: "",
		},
		bottomTxt: {
			default: "",
		},
	},
	data() {
		return {
			mobile: false,
		};
	},
	computed: {
		infoData() {
			const mobile = window.innerWidth < 1024;
			return {
				title: this.parseTxt(
					this.data.title,
					mobile ? window.innerWidth : 678,
					mobile ? 22 : 50
				),
				content: this.parseTxt(
					this.data.content,
					mobile ? window.innerWidth * 0.8 : 330,
					mobile ? 14 : 16
				),
			};
		},
	},
	mounted() {
		this.setObserverFunction([
			{
				name: ".top-txt",
				id: 1,
				animationName: "fadeGrm",
				distance: 300,
			},
			{
				name: ".add-txt",
				id: 2,
				animationName: "fadeGrm",
				distance: 300,
				delay: 200,
			},
		]);
		this.mobile = window.innerWidth < 1024;
	},
};
</script>

<style lang="scss" scoped>
@import "../styles/blockStyles.scss";
@import "../styles/config.scss";

.top-txt {
	font-size: ptr(50px);
	font-weight: 300;
	line-height: ptr(56px);
	width: ptr(688px);
	white-space: pre-wrap;
	display: flex;
	flex-direction: column;
}
span {
	opacity: 0;
}
.bottom-txt {
	width: ptr(340px);
	position: relative;
	margin-top: ptr(108px);
}
.add-txt {
	font-size: ptr(16px);
	line-height: ptr(25px);
	white-space: pre-wrap;
	display: flex;
	flex-direction: column;
}
@media (max-width: 1024px) {
	.top-txt {
		width: 100%;
		font-size: ptr(34px);
		line-height: ptr(36px);
	}
	.bottom-txt {
		width: unset;
		margin-top: ptr(50px);
	}
	.add-txt {
		font-size: ptr(14px);
		line-height: ptr(18px);
	}
	.side-txt {
		display: none;
	}
}
</style>
