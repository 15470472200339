<template>
	<div class="infinite-line-container">
		<div
			class="infinite-line"
			:style="`transform: translate3d(${-lineTrs}px,0,0)`"
		>
			<div v-for="index in 3" :key="index" class="img-line">
				<img
					v-for="(p, k) in data.images"
					:src="p"
					alt="Фото сотрудника"
					:key="k"
				/>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		data: {
			default: () => ({
				images: [],
			}),
		},
	},
	data() {
		return {
			lineTrs: 0,
		};
	},
	methods: {
		moveLine() {
			const el = this.$el.querySelector(".img-line");
			const mobile = window.innerWidth;
			const moveLine = () => {
				setTimeout(
					() => {
						if (this.lineTrs >= el.scrollWidth) {
							this.lineTrs = 0;
						} else {
							this.lineTrs += 0.5;
						}
						requestAnimationFrame(moveLine);
					},
					mobile ? 30 : 1
				);
			};
			moveLine();
		},
	},
	watch: {
		data() {
			this.moveLine();
		},
	},
	mounted() {
		if (this.data.images.length > 0) {
			this.moveLine();
		}
	},
};
</script>

<style lang="scss" scoped>
@import "../styles/blockStyles.scss";
@import "../styles/config.scss";

.infinite-line {
	display: flex;
	font-size: ptr(60px);
	font-weight: 300;
	text-transform: uppercase;
	position: relative;
	color: #535353;
	margin: ptr(200px) 0;
}
.img-line {
	display: grid;
	grid-auto-flow: column;
	grid-auto-columns: max-content;
	img {
		background: #535353;
		width: ptr(200px);
		height: ptr(200px);
		border-radius: 50%;
		object-fit: cover;
	}
}
.infinite-line-container {
	position: relative;
	width: 100vw;
}
@media (max-width: 1024px) {
	.mobile {
		display: flex;
	}
	.abs {
		position: absolute;
		right: 100%;
	}
}
</style>
