<template>
	<div class="event-block">
		<div class="event-logo">
			<div class="logo-container">
				<img :src="data.project.logo" alt="Лого проекта" />
			</div>
			<div @click="filterPrj" class="arrow-down">
				<img
					v-if="thisPrj"
					src="/static/icons/×.svg"
					alt="Убрать фильтрацию по проектам"
				/>
				<div class="hover-message">
					{{
						thisPrj
							? $t("show[0]")
							: `${$t("show[1]")} ${data.project.name}`
					}}
				</div>
			</div>
		</div>
		<div class="content-container">
			<div class="data">
				<div class="time-line"></div>
				<span>
					{{ parsedDate(data.date) }}
				</span>
			</div>
			<div class="news-container">
				<news-container
					v-for="(d, k) in data.items"
					:key="k"
					:info="d"
					:top="top"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import anmMixin from "@/mixins/anmMixin";
import NewsContainer from "./NewsContainer.vue";
export default {
	components: { NewsContainer },
	mixins: [anmMixin],
	props: {
		data: {
			default: () => ({
				data: "",
				items: [],
				project: {},
			}),
		},
		project: {
			default: "",
		},
		top: {
			default: false,
		},
	},
	methods: {
		parsedDate(str) {
			const addZero = (d) => {
				return d > 9 ? d : "0" + d;
			};
			const d = new Date(str);
			if (str === "") {
				return {
					date: "",
					time: "",
				};
			}
			return `${addZero(d.getDate())}.${addZero(
				d.getMonth() + 1
			)}.${d.getFullYear()}`;
		},
		filterPrj() {
			const rectTop = this.$el.getBoundingClientRect().top;
			this.$emit("getProjectId", {
				id: this.thisPrj ? "" : this.data.project.id,
				top: rectTop,
				el: this.$el,
			});
		},
	},
	computed: {
		thisPrj() {
			return this.data.project.id === this.project;
		},
	},
	mounted() {
		this.setObserverFunction([
			{
				name: ".data",
				animationName: "dataShow",
			},
			{
				name: ".event-logo",
				animationName: "fade",
			},
		]);
	},
};
</script>

<style lang="scss" scoped>
@import "./styles/config.scss";

.content-container {
	display: grid;
	gap: ptr(45px);
}

.event-block {
	position: relative;
	display: grid;
	width: ptr(908px);
	grid-template-columns: ptr(180px) ptr(612px);
	gap: ptr(136px);
	left: ptr(-30px);
}
.event-logo {
	display: flex;
	align-items: center;
	align-self: flex-start;
	justify-content: flex-end;
	margin-top: -15%;
	position: sticky;
	top: 40%;
	opacity: 0;
}
.arrow-down {
	display: flex;
	align-items: center;
	justify-content: center;
	border: 2px solid white;
	border-radius: 50%;
	width: ptr(25px);
	height: ptr(25px);
	margin-left: ptr(25px);
	position: relative;
	img {
		pointer-events: none;
		margin: 1px ptr(-1px) 0 0;
		width: ptr(6.5px);
	}
	&:hover {
		.hover-message {
			pointer-events: all;
			opacity: 1;
		}
	}
}
.hover-message {
	display: flex;
	align-items: center;
	justify-content: center;
	border: 2px solid #979797;
	background: black;
	position: absolute;
	z-index: 2;
	font-size: ptr(16px);
	border-radius: ptr(11px);
	width: ptr(184px);
	box-sizing: border-box;
	padding: ptr(15px);
	bottom: 130%;
	text-align: center;
	pointer-events: none;
	opacity: 0;
	transition: $trs;
}
.logo-container {
	img {
		width: ptr(122px);
		height: ptr(100px);
		object-fit: contain;
	}
}
.data {
	font-size: ptr(26px);
	font-weight: 500;
	margin-bottom: ptr(25px);
	position: relative;
	display: flex;
	align-items: center;
	span {
		opacity: 0;
	}
}

.data-down {
	grid-row: 2;
}

.news-container {
	display: grid;
	gap: ptr(200px);
}

.time-line {
	width: ptr(40px);
	height: ptr(20px);
	display: flex;
	align-items: center;
	position: absolute;
	left: ptr(-112px);
}

.dataShow {
	span {
		animation: minfadeUp 0.6s $anmTrs forwards;
	}
	&::after {
		width: ptr(433px);
		opacity: 1;
	}
}

@media (max-width: 1024px) {
	.content-container {
		gap: 0;
	}

	.event-block {
		width: ptr(314px);
		grid-template-columns: ptr(314px);
		gap: ptr(23px);
		left: ptr(15px);
	}
	.event-logo {
		position: relative;
		justify-content: start;
		margin-top: 0;
		top: unset;
	}
	.logo-container img {
		width: ptr(67px);
	}
	.data {
		font-size: ptr(20px);
		margin-bottom: ptr(23px);
	}

	.news-container {
		display: grid;
		gap: ptr(76px);
	}

	.time-line {
		display: none;
	}
}
</style>
