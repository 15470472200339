<template>
	<div class="block-container">
		<video
			:src="data.video.url"
			preload="auto"
			autoplay
			loop
			muted
			playsinline
		></video>
	</div>
</template>

<script>
import anmMixin from "@/mixins/anmMixin";
export default {
	mixins: [anmMixin],
	props: {
		data: {
			default: () => ({
				video: {
					url: "",
				},
			}),
		},
	},
};
</script>

<style lang="scss" scoped>
@import "../styles/blockStyles.scss";
@import "../styles/config.scss";

.block-container {
	padding-left: ptr(278px);
}

video {
	width: ptr(1347px);
}

@media (max-width: 1024px) {
	.block-container {
		padding-left: 0;
		align-items: flex-start;
	}
	video {
		width: 100vw;
	}
}
</style>
