export default {
	data() {
		return {
			isMobile: false,
			elements: {},
			height: 0,
			ctx: null,
			fontSize: null,
			observer: null,
			observerElements: {},
		};
	},
	methods: {
		smoothScrlAnm(slc, type, val) {
			if (this.height === 30000222) {
				this.height = window.innerHeight / 2;
			}
			if (!(slc in this.elements)) {
				if (this.$el.querySelector(slc) !== null) {
					this.elements[slc] = this.$el.querySelector(slc);
				}
			}
			if (this.elements[slc] !== undefined) {
				this.elements[slc].style[type] = val;
			}
		},
		setObserverFunction(elements) {
			for (let el of elements) {
				const htmlEl = this.$el.querySelector(el.name);
				htmlEl.setAttribute("animate-id", el.name);
				this.observerElements[el.name] = el;
				this.observer.observe(htmlEl);
			}
		},
		// scrollListener() {
		// 	for (let i = 0; i < this.animationElementsArray.length; i++) {
		// 		const anEl = this.animationElementsArray[i];
		// 		const el = this.$el.querySelector(anEl.name);
		// 		const rect = el.getBoundingClientRect();
		// 		const grd = rect.top < rect.bottom ? rect.top : rect.bottom;
		// 		if (Math.abs(grd) < this.height + anEl.distance) {
		// 			setTimeout(() => {
		// 				el.classList.add(anEl.animationName);
		// 				this.animationElementsArray.splice(i, 1);
		// 			}, anEl.delay);
		// 		}
		// 	}
		// },
		scrlAnmOn(slc, anm, fix, delay = 0) {
			if (this.height === 0) {
				this.height = window.innerHeight / 1.6;
			}
			if (!(slc in this.elements)) {
				this.elements[slc] = this.$el.querySelector(slc);
			}
			const el = this.elements[slc];
			if (el.classList.contains(anm)) {
				return;
			}
			const rect = el.getBoundingClientRect();
			const grd = rect.top < rect.bottom ? rect.top : rect.bottom;
			if (Math.abs(grd) < this.height + fix) {
				setTimeout(() => {
					el.classList.add(anm);
				}, delay);
			}
		},
		parseTxt(text, length, size) {
			if (!this.ctx) {
				this.ctx = document.createElement("canvas").getContext("2d");
			}
			if (!this.fontSize) {
				this.fontSize = parseFloat(
					window.getComputedStyle(document.documentElement).fontSize
				);
			}
			const ctx = this.ctx;
			const fontSize = this.fontSize;
			const adativeLength = (length / 16) * fontSize;
			ctx.font = `${(size / 16) * fontSize}px SF Pro Display, sans-serif`;
			const checkWidh = (txt) => {
				let res = 0;
				for (let t of txt) {
					res += ctx.measureText(t).width;
				}
				return res <= adativeLength;
			};
			const txt = text
				.replaceAll(/\n\n/g, " /~1 ")
				.replaceAll(/\n/g, " /~ ")
				.split(" ");
			const res = [];
			let ch = 0;
			for (let t of txt) {
				if (res[ch] === undefined) {
					res[ch] = t + " ";
				} else if (
					t !== "/~1" &&
					t !== "/~" &&
					checkWidh(res[ch] + t)
				) {
					res[ch] = res[ch] + t + " ";
				} else if (t === "/~1") {
					ch += 2;
					res[ch] = "\n";
				} else if (t === "/~") {
					ch += 1;
				} else {
					ch += 1;
					res[ch] = t + " ";
				}
			}
			return res;
		},
	},
	// watch: {
	// 	scroll() {
	// 		if (this.animationElementsArray.length > 0) {
	// 			this.scrollListener();
	// 		}
	// 	},
	// },
	mounted() {
		const options = {
			root: null,
			rootMargin: `20% 0% -20%`,
			threshold: 0,
		};
		const callback = (entries) => {
			entries.forEach((e) => {
				if (e.isIntersecting) {
					const atr = e.target.getAttribute("animate-id");
					const obj = this.observerElements[atr];
					setTimeout(() => {
						e.target.classList.add(obj.animationName);
					}, obj.delay);
				}
			});
		};
		this.observer = new IntersectionObserver(callback, options);

		const ANIMATEDCLASSNAME = "animated";
		const ELEMENTS = this.$el.querySelectorAll(".HOVER");
		const ELEMENTS_SPAN = [];

		ELEMENTS.forEach((element, index) => {
			if (element.classList[1] == "FLASH") {
				element.addEventListener("animationend", () => {
					element.classList.remove(ANIMATEDCLASSNAME);
				});
			}
			if (!ELEMENTS_SPAN[index])
				ELEMENTS_SPAN[index] = element.querySelector("span");

			element.addEventListener("mouseover", (e) => {
				const x = e.clientX - element.getBoundingClientRect().left;
				const y = e.clientY - element.getBoundingClientRect().top;
				ELEMENTS_SPAN[index].style.left = x + "px";
				ELEMENTS_SPAN[index].style.top = y + "px";
			});

			element.addEventListener("mouseout", (e) => {
				const x = e.clientX - element.getBoundingClientRect().left;
				const y = e.clientY - element.getBoundingClientRect().top;
				ELEMENTS_SPAN[index].style.left = x + "px";
				ELEMENTS_SPAN[index].style.top = y + "px";
			});
		});
	},
};
