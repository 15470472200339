<template>
	<div class="carousel">
		<div class="carousel-trail" :style="trailStyles">
			<Video
				v-if="video"
				class="carousel-video"
				:url="video.url"
				@load="setVideoRef"
				:class="{
					inactive: currentIndex !== 0,
				}"
			/>

			<img
				v-for="(img, i) in images"
				class="carousel-image"
				:class="{
					inactive: currentIndex !== i + !!video,
				}"
				:key="i"
				:src="img"
				:alt="'image-' + i"
				:ref="setImageRef"
			/>
		</div>
		<arrow
			v-if="currentIndex > 0"
			class="carousel-arrow left"
			direction="left"
			@click="moveLeft"
		/>
		<arrow
			v-if="currentIndex < carouselItems.length - 1"
			class="carousel-arrow right"
			direction="right"
			@click="moveRight"
		/>
	</div>
</template>

<script>
import Video from "./Video";
import Arrow from "./Arrow";

export default {
	name: "Carousel",
	components: { Arrow, Video },
	props: {
		video: {
			type: String,
			required: true,
		},
		images: {
			type: Array,
			required: true,
		},
	},
	data() {
		return {
			carouselItems: [],
			currentIndex: 0,
			windowWidth: 0,
		};
	},
	computed: {
		offsetLeft() {
			if (this.carouselItems.length === 0) return 0;

			const currElem = this.carouselItems[this.currentIndex];

			return -currElem.offsetLeft;
		},
		trailStyles() {
			return {
				transform: `translate3d(${this.offsetLeft}px, 0, 0)`,
			};
		},
	},
	methods: {
		setImageRef(el) {
			if (el && !this.carouselItems.includes(el))
				this.carouselItems.push(el);
		},
		setVideoRef(el) {
			if (el && !this.carouselItems.includes(el))
				this.carouselItems.unshift(el);
		},
		moveLeft() {
			this.currentIndex = Math.max(this.currentIndex - 1, 0);
		},
		moveRight() {
			this.currentIndex = Math.min(
				this.currentIndex + 1,
				this.carouselItems.length - 1
			);
		},
		resizeHandler() {
			this.windowWidth = document.documentElement.clientWidth;
		},
	},
	mounted() {
		window.addEventListener("resize", this.resizeHandler);
	},
	beforeUnmount() {
		window.removeEventListener("resize", this.resizeHandler);
	},
};
</script>

<style lang="scss" scoped>
@import "../../styles/config";

.carousel {
	position: relative;
	display: flex;
	align-items: center;
	width: calc(100% - #{ptr(340px)});
	z-index: 10;
	margin-left: ptr(340px);
	box-sizing: border-box;
}

.carousel-trail {
	display: grid;
	grid-auto-columns: max-content;
	grid-auto-flow: column;
	gap: ptr(10px);
	// transition: transform 0.8s cubic-bezier(0.25, 0.74, 0.22, 0.99);
}

.carousel-video,
.carousel-image {
	height: ptr(700px);
	width: ptr(1220px);
	object-fit: cover;
	transition: $trs;
	position: relative;
}

.carousel-image {
	opacity: 0.7;
}

.inactive {
	opacity: 0;
	pointer-events: none;
	transition: none;
}

.carousel-arrow {
	position: absolute;
	top: ptr(320px);

	&.left {
		left: ptr(40px);
	}

	&.right {
		right: ptr(380px);
	}
}
@media (max-width: 1024px) {
	.carousel {
		padding: 0;
		margin: 0;
		width: 100vw;
	}
	.carousel-video,
	.carousel-image {
		height: ptr(211px);
		width: 100vw;
		object-fit: cover;
	}
	.carousel-arrow {
		top: ptr(92px);
		&.left {
			left: ptr(14px);
		}

		&.right {
			right: ptr(14px);
		}
	}
}
</style>
