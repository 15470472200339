<template>
	<!-- <div
		ref="customCursorEle"
		:class="`custom_cursor ${hovered ? 'hovered' : ''}`"
		:style="`transform: translate3d(${circlePosX}px,${circlePosY}px,0)`"
	></div> -->
	<side-menu :scroll="scroll" />
	<router-view v-slot="{ Component }" :scroll="scroll" :mobile="mobile">
		<transition name="fade" mode="out-in">
			<component :is="Component" :key="$route.fullPath"></component>
		</transition>
	</router-view>
</template>

<script>
import SideMenu from "./components/SideMenu.vue";
// import SmoothScroll from "@/components/Services/SmoothScroll";
import CustomScroll from "@/components/Services/CustomScroll";

export default {
	components: { SideMenu },
	data() {
		return {
			scroll: 0,
			mobile: false,
		};
	},
	mounted() {
		const locale = localStorage.getItem("locale");
		if (!locale) {
			localStorage.setItem("locale", this.$i18n.locale);
		} else {
			this.$i18n.locale = locale;
		}
		// window.SmoothScrollOptions = { keyboardSupport: false };
		// SmoothScroll({ keyboardSupport: false });
		this.mobile = window.innerWidth < 1024;
		CustomScroll.init();
		window.addEventListener(
			"scroll",
			() => {
				this.scroll = document.documentElement.scrollTop;
			},
			{ passive: true }
		);
	},
};
</script>

<style lang="scss">
@import "./components/styles/config.scss";
#app {
	font-family: $main-font, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: $text-color;
	cursor: url("/static/icons/cursor.svg") 10 11, auto;
}
html {
	background: black;
	-webkit-text-size-adjust: none;
}
body {
	background: black;
	margin: 0;
	*::selection {
		background: rgb(11, 34, 202);
		color: white;
	}
}
a {
	font-family: $main-font;
}
button,
a,
.main-info,
.event,
.arrow-down,
.group-name,
.slc,
.arrow {
	cursor: url("/static/icons/cursor-active.svg") 10 11, pointer;
}
ul {
	margin: 0;
	padding: 0;
}
li {
	list-style: none;
}
p {
	margin: 0;
}
.next {
	display: none;
}

.HOVER {
	position: relative;
	overflow: hidden;
	color: $text-color;
	-webkit-mask-image: -webkit-radial-gradient(white, black);
	span {
		position: absolute;
		pointer-events: none;
		width: 0;
		height: 0;
		border-radius: 100%;
		background: #ffffff;
		transform: translate3d(-50%, -50%, 0);
		transition: width $trs, padding-top $trs;
	}
	p {
		mix-blend-mode: difference;
	}
	&:hover {
		span {
			width: calc(100% * 2.25);
			padding-top: calc(100% * 2.25);
		}
	}
}

.fade-enter-active {
	animation: fade 0.7s ease-in-out;
}
.fade-leave-active {
	animation: fade 0.7s ease-in-out reverse;
}

.next-container {
	display: none;
}

@media (max-width: 1024px) {
	.custom_cursor {
		display: none;
	}
	.next-container {
		width: 100vw;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.next {
		display: flex;
		width: ptr(158px);
		height: ptr(158px);
		align-items: center;
		justify-content: center;
		color: $text-color;
		text-decoration: none;
		font-weight: 600;
		font-size: ptr(12px);
		margin: ptr(100px) 0;
		border-radius: 50%;
		border: 1px solid white;
	}
}
</style>
