<template>
	<div class="block-container">
		<div class="top-txt">
			<span v-for="(p, k) in infoData.title" :key="k">
				{{ p }}
			</span>
		</div>
		<sticker
			class="sticker"
			:scroll="paralaxScrl"
			:data="data.sticker"
		></sticker>
		<div class="bottom-txt">
			<div class="side-txt">
				{{ data.est }}
			</div>
			<p class="add-txt">
				<span v-for="(p, k) in infoData.content" :key="k">
					{{ p }}
				</span>
			</p>
		</div>
	</div>
</template>

<script>
import anmMixin from "@/mixins/anmMixin";
import Sticker from "../Sticker.vue";
export default {
	components: { Sticker },
	mixins: [anmMixin],
	props: {
		data: {
			default: () => ({
				title: "",
				content: "",
				est: "",
				sticker: {
					color: "#496066",
					text: "Explore all —",
					url: "",
				},
			}),
		},
		scroll: {
			default: 0,
		},
	},
	data() {
		return {
			paralaxScrl: 0,
		};
	},
	computed: {
		infoData() {
			const mobile = window.innerWidth < 1024;
			return {
				title: this.parseTxt(
					this.data.title,
					mobile ? window.innerWidth * 0.8 : 688,
					mobile ? 22 : 50
				),
				content: this.parseTxt(
					this.data.content,
					mobile ? window.innerWidth * 0.8 : 360,
					mobile ? 14 : 16
				),
			};
		},
	},
	mounted() {
		this.setObserverFunction([
			{
				name: ".top-txt",
				id: 1,
				animationName: "fadeGrm",
				distance: 300,
			},
			{
				name: ".paralax-container",
				id: 2,
				animationName: "fade",
				distance: 200,
				delay: 500,
			},
			{
				name: ".side-txt",
				id: 3,
				animationName: "fadeLeft",
				distance: 300,
				delay: 500,
			},
			{
				name: ".add-txt",
				id: 4,
				animationName: "fadeGrm",
				distance: 300,
				delay: 200,
			},
		]);
	},
	watch: {
		scroll() {
			// this.scrlAnmOn(".top-txt", "fadeGrm", 300);
			// this.scrlAnmOn(".sticker", "fade", 200, 500);
			// this.scrlAnmOn(".add-txt", "fadeGrm", 300, 200);
			// this.scrlAnmOn(".side-txt", "fadeLeft", 300, 500);
			const rect = this.$el.getBoundingClientRect();
			if (rect.top - window.innerHeight < 0 && rect.bottom > 0) {
				this.paralaxScrl = rect.top - window.innerHeight;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
@import "../styles/blockStyles.scss";
@import "../styles/config.scss";

.top-txt {
	font-size: ptr(50px);
	font-weight: 300;
	line-height: ptr(56px);
	width: ptr(688px);
	white-space: pre-wrap;
	display: flex;
	flex-direction: column;
	span {
		display: inline-block;
		opacity: 0;
	}
}
.bottom-txt {
	width: ptr(350px);
	position: relative;
	margin-top: ptr(108px);
}
.add-txt {
	font-size: ptr(16px);
	line-height: ptr(27px);
	display: flex;
	flex-direction: column;
	span {
		white-space: pre;
		display: inline-block;
		opacity: 0;
		line-height: ptr(25px);
	}
}
.side-txt {
	font-size: ptr(14px);
	position: absolute;
	left: ptr(-160px);
	top: ptr(5px);
	opacity: 0;
}

@media (max-width: 1024px) {
	.top-txt {
		width: 100%;
		font-size: ptr(22px);
		line-height: ptr(26px);
	}
	.bottom-txt {
		width: unset;
		margin-top: ptr(50px);
	}
	.add-txt {
		font-size: ptr(14px);
		span {
			line-height: ptr(18px);
		}
	}
	.side-txt {
		display: none;
	}
}
</style>
