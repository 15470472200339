<template>
	<div :class="rootClass" :ref="setContainer" @click="toggleVideo">
		<video
			:src="url"
			:ref="setVideo"
			:class="{ playing: playing }"
			@timeupdate="getVideoPercent($event.target)"
		></video>
		<div
			class="video-playground"
			:class="{ play: showButton }"
			:ref="setPlayground"
		>
			<div :ref="setPlayButton" class="play-button" :style="buttonStyles">
				<svg viewBox="0 0 489 489">
					<circle fill="transparent" r="49.4%" cx="50%" cy="50%" />
				</svg>
				<span>PLAY</span>
			</div>
		</div>
	</div>
</template>

<script>
import { ref, computed } from "vue";
import useMouseFollow from "@/hook/followMouse";

export default {
	name: "Video",
	props: {
		url: {
			type: String,
		},
	},
	setup(props, { emit }) {
		const container = ref(null);
		const playButton = ref(null);
		const playground = ref(null);
		const video = ref(null);
		const showButton = ref(false);
		const playing = ref(false);

		const { pos: buttonPos, isIdle: isButtonIdle } = useMouseFollow(
			playground,
			playButton,
			undefined,
			showButton
		);

		const rootClass = computed(() => ({
			"video-container": true,
			active: true,
		}));

		const buttonStyles = computed(() => ({
			transform: !isButtonIdle.value
				? `translate3d(${buttonPos.x}px, ${buttonPos.y}px, 0)`
				: "",
		}));

		const setContainer = (el) => {
			if (el && !container.value) {
				container.value = el;
				emit("load", container.value);
			}
		};

		const setPlayButton = (el) => {
			if (el && !playButton.value) playButton.value = el;
		};

		const setPlayground = (el) => {
			if (el && !playground.value) playground.value = el;
		};

		const setVideo = (el) => {
			if (el && !video.value) video.value = el;
		};

		const getVideoPercent = (el) => {
			const timing = (el.currentTime / el.duration) * 100;
			const circle = playButton.value.querySelector("circle");
			const radius = circle.r.baseVal.value;
			const circumference = radius * 2 * Math.PI;
			circle.style.strokeDasharray = `${circumference} ${circumference}`;
			circle.style.strokeDashoffset =
				circumference - (timing / 100) * circumference;
		};

		const toggleVideo = (e) => {
			e.preventDefault();

			if (!showButton.value) {
				video.value.play();
				showButton.value = true;
				playing.value = true;
			} else {
				video.value.pause();
				video.value.currentTime = 0;
				showButton.value = false;
			}
		};

		return {
			rootClass,
			buttonStyles,
			showButton,
			setContainer,
			setPlayButton,
			setPlayground,
			setVideo,
			toggleVideo,
			playing,
			getVideoPercent,
		};
	},
};
</script>

<style lang="scss" scoped>
@import "../../styles/config";

.video-container {
	position: relative;
	overflow: hidden;
	video {
		height: 100%;
		opacity: 0.8;
		background: #535353;
	}
	.playing {
		opacity: 1;
	}
}

.video-playground {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	transition: $trs;
	&:hover {
		background: rgba(0, 0, 0, 0.2);
	}
}

.play-button {
	display: flex;
	align-items: center;
	justify-content: center;
	border: 2px solid white;
	border-radius: 50%;
	color: white;
	transform-origin: center;
	width: ptr(187px);
	height: ptr(187px);
	box-sizing: border-box;
	position: relative;
	transition: opacity $trs, width $trs, height $trs;

	span {
		position: absolute;
		pointer-events: none;
	}

	svg {
		position: absolute;
		width: ptr(187px);
		height: ptr(187px);
		transition: opacity $trs, width $trs, height $trs;
		transform: rotate(-90deg);
		opacity: 0;
		circle {
			stroke-width: 3px;
			stroke: white;
			transition: $trs;
		}
	}

	&.active {
		transform: scale3d(1, 1, 1);
	}
}

.play {
	.play-button {
		width: ptr(497px);
		height: ptr(497px);
		border: 3px solid rgba(255, 255, 255, 0.219);
		color: rgba(0, 0, 0, 0);
		opacity: 0;
		svg {
			width: ptr(499px);
			height: ptr(499px);
			opacity: 1;
		}
	}
	&:hover {
		.play-button {
			opacity: 1;
		}
	}
}
@media (max-width: 1024px) {
	.video-container {
		video {
			width: 100vw;
		}
	}
	.play-button {
		width: ptr(101px);
		height: ptr(101px);
		border: 2px solid white;
		font-size: ptr(14px);
		font-weight: 600;
		svg {
			width: ptr(101px);
			height: ptr(101px);
		}
	}
	.play {
		.play-button {
			width: ptr(200px);
			height: ptr(200px);
			svg {
				width: ptr(200px);
				height: ptr(200px);
			}
		}
	}
}
</style>
