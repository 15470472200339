<template>
	<div class="block-container">
		<h2>
			<span>
				{{ $t("whatNext[0]") }}
			</span>
			<br />
			<span>
				{{ $t("whatNext[1]") }}
			</span>
		</h2>
		<div class="img-container">
			<img :src="data.banner" alt="Картинка отражающая будущее проекта" />
		</div>
		<div class="text-container">
			<p>
				<span v-for="(p, k) in parseDescription[0]" :key="k">
					{{ p }}
				</span>
			</p>
			<p v-if="parseDescription[1].length > 0">
				<span v-for="(p, k) in parseDescription[1]" :key="k">
					{{ p }}
				</span>
			</p>
		</div>
	</div>
</template>

<script>
import anmMixin from "@/mixins/anmMixin";
export default {
	mixins: [anmMixin],
	props: {
		data: {
			default: () => ({
				banner: "",
				text:
					"За 5 лет компания стала ключевой и универсальной платформой для проведения мероприятий всевозможных форматов. Первоклассное обслуживание, изысканная кухня и стильные интерьеры с топовым оборудованием определяют статус наших мероприятий. Клиентами LOFT HALL являются крупнейшие бренды и корпорации: Google, PwC, Сбербанк Coca-Cola, Warner Bros.\nЗа 5 лет компания стала ключевой и универсальной платформой для проведения мероприятий всевозможных форматов. Первоклассное обслуживание, изысканная кухня и стильные интерьеры с топовым оборудованием определяют статус наших мероприятий. Клиентами LOFT HALL являются крупнейшие бренды и корпорации: Google, PwC, Сбербанк Coca-Cola, Warner Bros.",
			}),
		},
	},
	computed: {
		parseDescription() {
			const mobile = window.innerWidth < 1024;
			const res = this.parseTxt(this.data.text, 290, mobile ? 14 : 16);
			return [res.slice(0, 13), res.slice(9, res.length)];
		},
	},
	mounted() {
		this.setObserverFunction([
			{
				name: "h2",
				animationName: "sideWords",
				distance: 300,
			},
			{
				name: ".img-container",
				animationName: "zoomOut",
				distance: 300,
			},
		]);
	},
};
</script>

<style lang="scss" scoped>
@import "../styles/blockStyles.scss";
@import "../styles/config.scss";

.block-container {
	padding-left: 0;
}

h2 {
	font-size: ptr(60px);
	font-weight: 300;
	line-height: ptr(66px);
	position: relative;
	left: ptr(955px);
	width: fit-content;
	span {
		opacity: 0;
	}
	span:nth-child(3) {
		margin-left: ptr(60px);
	}
	margin: 0 0 ptr(150px);
}

.img-container {
	height: ptr(607px);
	overflow: hidden;
	display: flex;
	align-items: center;
	clip-path: inset(0 0 100%);
	transition: 1s $anmTrs;
	img {
		transition: 2s cubic-bezier(0.85, 0, 0.15, 1);
		transform: scale(1.5);
		width: 100%;
	}
}

.zoomOut {
	clip-path: inset(0 0 0%);
	img {
		transform: scale(1);
	}
}

.text-container {
	display: grid;
	justify-content: center;
	grid-template-columns: repeat(2, max-content);
	gap: ptr(60px);
	padding-left: ptr(650px);
	p {
		margin-top: ptr(70px);
		font-size: ptr(16px);
		font-weight: 300;
		line-height: ptr(25px);
		width: ptr(295px);
	}
}

@media (max-width: 1024px) {
	h2 {
		font-size: ptr(45px);
		font-weight: 500;
		line-height: ptr(41px);
		left: ptr(15px);
		span:nth-child(3) {
			margin-left: ptr(20px);
		}
		margin: 0 0 ptr(60px);
	}

	.img-container {
		height: ptr(175px);
	}

	.zoomOut {
		clip-path: inset(0 0 0%);
		img {
			transform: scale(1);
		}
	}

	.text-container {
		grid-template-columns: max-content;
		gap: ptr(55px);
		justify-content: flex-start;
		padding-left: ptr(15px);
		margin-top: ptr(68px);
		p {
			margin: 0;
			font-size: ptr(14px);
			line-height: ptr(20px);
			width: ptr(319px);
		}
	}
}
</style>
