import {
	ref,
	reactive,
	computed,
	onMounted,
	watch,
	onBeforeUnmount,
} from "vue";

const defaultOptions = {
	speed: 0.07,
	initPos: {
		x: 0,
		y: 0,
	},
};

export default function useMouseFollow(
	containerRef,
	targetRef,
	userOptions,
	vidParameters
) {
	const options = computed(() =>
		Object.assign({}, defaultOptions, userOptions)
	);
	const { pos: cursorPos, isActive } = useCursor(containerRef);
	const currentPos = reactive({ ...options.value.initPos });
	const targetCoordsOffset = reactive({
		x: 0,
		y: 0,
	});
	const isIdle = ref(true);

	const mapParentToTarget = (p) => ({
		x: p.x - targetCoordsOffset.x,
		y: p.y - targetCoordsOffset.y,
	});

	const mainLoop = () => {
		if (isIdle.value || window.innerWidth < 1024) return;
		const active = isActive.value;
		const target = targetRef.value;
		const targetPos =
			active && !vidParameters.value
				? mapParentToTarget(cursorPos)
				: options.value.initPos;

		let dirVector = {
			x: targetPos.x - currentPos.x,
			y: targetPos.y - currentPos.y,
		};

		if (active && !vidParameters.value) {
			dirVector.x -= target.getBoundingClientRect().width / 2.15;
			// TODO: WHY IS THIS NEEDED?! ?!
			dirVector.y -= target.getBoundingClientRect().height / 2.15;
		}

		const norm = Math.sqrt(
			dirVector.x * dirVector.x + dirVector.y * dirVector.y
		);

		dirVector = {
			x: dirVector.x * options.value.speed,
			y: dirVector.y * options.value.speed,
		};

		currentPos.x += dirVector.x;
		currentPos.y += dirVector.y;

		if (!active && norm < 1) {
			isIdle.value = true;
			currentPos.x = targetPos.x;
			currentPos.y = targetPos.y;
		}

		requestAnimationFrame(mainLoop);
	};

	watch(isActive, (newVal) => {
		if (newVal) {
			if (isIdle.value) {
				requestAnimationFrame(mainLoop);
			}

			isIdle.value = false;
		}
	});

	onMounted(() => {
		targetCoordsOffset.x = targetRef.value.offsetLeft || 0;
		targetCoordsOffset.y = targetRef.value.offsetTop || 0;
	});

	return { pos: currentPos, isIdle };
}

export function useCursor(container) {
	const pos = reactive({ x: 0, y: 0 });
	const isActive = ref(false);

	const mapCursorPos = (e) => {
		const rect = container.value.getBoundingClientRect();
		const x = e.clientX - rect.left;
		const y = e.clientY - rect.top;

		return { x, y };
	};

	// const onMouseEnter = () => {
	// 	isActive.value = true;
	// };

	const onMouseMove = (e) => {
		isActive.value = true;
		const mapped = mapCursorPos(e);

		pos.x = mapped.x;
		pos.y = mapped.y;
	};

	const onMouseLeave = () => {
		isActive.value = false;
	};

	onMounted(() => {
		// container.value.addEventListener("mouseenter", onMouseEnter);
		container.value.addEventListener("mousemove", onMouseMove);
		container.value.addEventListener("mouseleave", onMouseLeave);
	});

	onBeforeUnmount(() => {
		// container.value.removeEventListener("mouseenter", onMouseEnter);
		container.value.removeEventListener("mousemove", onMouseMove);
		container.value.removeEventListener("mouseleave", onMouseLeave);
	});

	const cnt = container.value;

	return { pos, isActive, cnt };
}
