<template>
    <carousel :video="data.video" :images="data.photos"/>
</template>

<script>
import Carousel from "./Carousel";

export default {
    name: "CarouselTest",
    components: { Carousel },
    props: {
        data:{
            default: ()=>({
                video: {url: ""},
                photos: []
            })
        }
    },
}
</script>

<style scoped>

</style>
