<template>
	<div class="infinite-line-container">
		<div
			class="infinite-line"
			:style="`transform: translate3d(${-lineTrs}px,0,0)`"
		>
			<div class="span-line">
				<span v-for="(p, k) in data.partners" :key="k">{{ p }}</span>
			</div>
			<div class="span-line">
				<span v-for="(p, k) in data.partners" :key="k">{{ p }}</span>
			</div>
			<div class="span-line">
				<span v-for="(p, k) in data.partners" :key="k">{{ p }}</span>
			</div>
			<div class="span-line">
				<span v-for="(p, k) in data.partners" :key="k">{{ p }}</span>
			</div>
		</div>
		<div
			class="infinite-line mobile"
			:style="`transform: translate3d(${lineTrs}px,0,0)`"
		>
			<div class="span-line">
				<span v-for="(p, k) in data.partners" :key="k">{{ p }}</span>
			</div>
			<div class="span-line abs">
				<span v-for="(p, k) in data.partners" :key="k">{{ p }}</span>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		data: {
			default: () => ({
				partners: [],
			}),
		},
	},
	data() {
		return {
			lineTrs: 0,
		};
	},
	methods: {
		moveLine() {
			const el = this.$el.querySelector(".span-line");
			const mobile = window.innerWidth;
			const moveLine = () => {
				setTimeout(
					() => {
						if (this.lineTrs >= el.scrollWidth) {
							this.lineTrs = 0;
						} else {
							this.lineTrs += 1;
						}
						requestAnimationFrame(moveLine);
					},
					mobile ? 30 : 1
				);
			};
			moveLine();
		},
	},
	watch: {
		data() {
			this.moveLine();
		},
	},
	mounted() {
		if (this.data.partners.length > 0) {
			this.moveLine();
		}
	},
};
</script>

<style lang="scss" scoped>
@import "../styles/blockStyles.scss";
@import "../styles/config.scss";

.infinite-line {
	display: flex;
	font-size: ptr(60px);
	margin-right: ptr(-40px);
	font-weight: 300;
	text-transform: uppercase;
	position: relative;
	color: #535353;
}
.span-line {
	margin-right: ptr(40px);
	display: grid;
	grid-auto-flow: column;
	grid-auto-columns: max-content;
	gap: ptr(40px);
}
.mobile {
	display: none;
}
.infinite-line-container {
	position: relative;
	width: 100vw;
}
@media (max-width: 1024px) {
	.mobile {
		display: flex;
	}
	.abs {
		position: absolute;
		right: 100%;
	}
}
</style>
