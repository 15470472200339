<template>
	<div class="block-container">
		<h2>
			PRESS KIT ™
		</h2>
		<ul class="dropdowns-container">
			<li
				v-for="(a, k) in dropdowns"
				:key="k"
				@click="a.open = !a.open"
				class="dropdown"
				:class="{
					open: a.open,
				}"
			>
				<div class="d-header">{{ a.name }} <span>→</span></div>
				<div class="hide-info">
					<a v-for="(h, i) in a.hrefs" :key="i" :href="h.url">
						<p>Cкачать вектор ({{ getExtension(h.url) }})</p>
						<span>{{ h.size }}kb</span>
					</a>
				</div>
			</li>
		</ul>
	</div>
</template>

<script>
import anmMixin from "@/mixins/anmMixin";
export default {
	mixins: [anmMixin],
	props: {
		data: {
			default: () => ({ logotype: [], media: [] }),
		},
	},
	data() {
		return {
			dropdowns: [],
		};
	},
	methods: {
		getExtension(str) {
			const splt = str.split(".");
			return "." + splt[splt.length - 1].toUpperCase();
		},
	},
	watch: {
		data() {
			this.dropdowns = [];
			for (const obj in this.data) {
				this.dropdowns.push({
					name: obj,
					hrefs: this.data[obj].map((e) => ({ ...e, open: false })),
				});
			}
		},
	},
	mounted() {
		this.setObserverFunction([
			{
				name: "h2",
				animationName: "fadeUp",
			},
			{
				name: ".dropdowns-container",
				animationName: "fadeList",
				delay: 300,
			},
		]);
	},
	created() {
		for (const obj in this.data) {
			this.dropdowns.push({
				name: obj,
				hrefs: this.data[obj].map((e) => ({ ...e, open: false })),
			});
		}
	},
};
</script>

<style lang="scss" scoped>
@import "../styles/blockStyles.scss";
@import "../styles/config.scss";

.block-container {
	padding-left: 0;
}

h2 {
	font-size: ptr(60px);
	font-weight: 300;
	line-height: ptr(66px);
	position: relative;
	left: ptr(948px);
	width: fit-content;
	opacity: 0;
	margin: 0 0 ptr(150px);
}
.dropdowns-container {
	display: grid;
	position: relative;
	left: ptr(950px);
	width: fit-content;
}
.dropdown {
	font-size: ptr(26px);
	font-weight: 300;
	opacity: 0;
}
.d-header {
	text-transform: capitalize;
	span {
		display: inline-block;
		transition: $trs;
	}
}
.hide-info {
	display: grid;
	grid-auto-flow: column;
	grid-auto-columns: max-content;
	transition: $trs, margin 0.5s $anmTrs;
	height: 0;
	padding-top: ptr(20px);
	box-sizing: border-box;
	overflow: hidden;
	a {
		color: white;
		text-decoration: none;
	}
	p {
		font-size: ptr(18px);
		position: relative;
		&::after {
			content: "";
			width: 100%;
			height: 1px;
			position: absolute;
			background: #d9d9d9;
			bottom: ptr(-4px);
			left: 0;
		}
	}
	span {
		font-size: ptr(13px);
		color: #727272;
	}
}
.open {
	.d-header span {
		transform: rotate(90deg);
	}
	.hide-info {
		height: ptr(72px);
		margin-bottom: ptr(20px);
	}
}

@media (max-width: 1024px) {
	h2 {
		left: ptr(15px);
		font-size: ptr(45px);
		font-weight: 500;
		margin-bottom: ptr(40px);
	}
	.dropdowns-container {
		left: ptr(15px);
	}
	.d-header {
		font-size: ptr(20px);
	}
	.hide-info {
		p {
			font-size: ptr(16px);
			&::after {
				bottom: ptr(-3px);
			}
		}
	}
}
</style>
