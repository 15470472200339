<template>
	<div class="block-container">
		<div class="side-txt">
			What before
		</div>
		<h2>
			<span>
				{{ $t("team[0]") }}
			</span>
			<br />
			<span>
				{{ $t("team[1]") }}
			</span>
		</h2>
		<div class="team-container">
			<div
				v-for="(p, k) in data.members"
				:key="k"
				class="team-participant"
				:id="'tmp' + k"
			>
				<div class="img-container">
					<img :src="p.photo" :alt="'Фото ' + p.name" />
				</div>
				<div class="name">
					<span v-for="(l, k) in p.name" :key="k">
						{{ l }}
					</span>
				</div>
				<div class="post">
					{{ p.position }}
				</div>
				<a :href="setType(p.contactType, p.phone)" class="phone">
					{{ p.phone }}
				</a>
				<div class="description">
					<p><span></span>{{ p.description }}</p>
					<!-- <p v-if="k === 0">
						Lorem ipsum dolor sit amet consectetur adipisicing elit.
						Corporis quam non at recusandae ipsam voluptatem
						reiciendis, nesciunt esse labore consequuntur cupiditate
						illum deleniti sint accusamus aliquid impedit,
						perferendis porro minima.
					</p> -->
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import anmMixin from "@/mixins/anmMixin";
export default {
	mixins: [anmMixin],
	props: {
		data: {
			default: () => ({
				members: [],
			}),
		},
		scroll: {
			default: 0,
		},
	},
	methods: {
		setType(type, str) {
			switch (true) {
				case type === "Почта" || type === "Email":
					return "mailto:" + str;
				case type === "Телефон" || type === "Phone":
					return "tel:" + str;
				case type === "Whatsapp":
					return "href=https://wa.me/" + str;
				case type === "Telegram":
					return "telegram.me/" + str;
				case type === "Facebook":
					return "https://www.facebook.com/" + str;
				case type === "Instagram":
					return "https://www.instagram.com/" + str;
				default:
					return str;
			}
		},
	},
	watch: {
		scroll() {
			const h = this.$el.getBoundingClientRect().top;
			if (h < 0 && window.innerWidth > 1024) {
				this.smoothScrlAnm(
					"#tmp1",
					"transform",
					`translate3d(0,${(h - 10) * 0.3}px,0)`
				);
				this.smoothScrlAnm(
					"#tmp2",
					"transform",
					`translate3d(0,${h * 0.2}px,0)`
				);
			}
		},
	},
	mounted() {
		this.setObserverFunction([
			{
				name: "h2",
				animationName: "sideWords",
			},
			{
				name: ".side-txt",
				animationName: "minfadeUp",
				delay: 400,
			},
			{
				name: "#tmp0",
				animationName: "pUp",
			},
			{
				name: "#tmp1",
				animationName: "pUp",
				delay: 100,
			},
			{
				name: "#tmp2",
				animationName: "pUp",
				delay: 150,
			},
		]);
	},
};
</script>

<style lang="scss" scoped>
@import "../styles/blockStyles.scss";
@import "../styles/config.scss";

.block-container {
	padding-left: ptr(555px);
}

.side-txt {
	position: absolute;
	left: ptr(765px);
	top: ptr(12px);
	opacity: 0;
}

h2 {
	font-size: ptr(60px);
	font-weight: 300;
	line-height: ptr(66px);
	position: relative;
	width: fit-content;
	left: ptr(403px);
	span {
		opacity: 0;
	}
	span:nth-child(3) {
		margin-left: ptr(60px);
	}
	margin: 0 0 ptr(150px);
}

.team-container {
	display: grid;
	gap: ptr(136px) ptr(62px);
	height: 100%;
	grid-template-columns: repeat(3, ptr(343px));
	grid-template-rows: ptr(745px);
}

.team-participant {
	display: flex;
	flex-direction: column;
	position: relative;
	align-items: flex-start;
}
.img-container {
	width: ptr(207px);
	height: ptr(207px);
	border-radius: 50%;
	overflow: hidden;
	background: #c3c3c3;
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}
.name {
	font-size: ptr(30px);
	font-weight: 300;
	margin: ptr(43px) 0 ptr(22px);
	white-space: break-spaces;
	span {
		display: inline-block;
		opacity: 0;
	}
}
.post {
	margin-bottom: ptr(40px);
	font-size: ptr(14px);
	font-weight: 300;
	opacity: 0;
}
.phone {
	padding: ptr(4px) ptr(14px);
	border: 2px solid #858585;
	color: #858585;
	border-radius: ptr(100px);
	font-size: ptr(14px);
	font-weight: 500;
	text-decoration: none;
	opacity: 0;
}
.description {
	display: grid;
	gap: ptr(60px);
	grid-auto-flow: column;
	grid-auto-columns: max-content;
	height: ptr(228px);
	margin-top: ptr(75px);
	font-size: ptr(16px);
	line-height: ptr(25px);
	font-weight: 300;
	p {
		opacity: 0;
		width: ptr(341px);
		white-space: pre;
	}
}
.team-participant:nth-child(1) {
	grid-column: 2;
	grid-row: 1;
}
.team-participant:nth-child(2) {
	grid-column: 1;
	top: ptr(-150px);
	grid-row: 2;
}
.team-participant:nth-child(3) {
	grid-column: 2;
	grid-row: 2;
	position: relative;
	top: ptr(381px);
}
.pUp {
	.name {
		@for $i from 1 through 40 {
			span:nth-child(#{$i}) {
				animation: micfadeUp $timing $anmTrs forwards;
				animation-delay: $i * 0.05s;
			}
		}
	}
	.post {
		animation: fade $timing 0.4s $anmTrs forwards;
	}
	.phone {
		animation: fade $timing 0.6s $anmTrs forwards;
	}
	.description {
		p {
			animation: fadeUp $timing 0.8s $anmTrs forwards;
		}
		p:nth-child(2) {
			animation: fadeUp $timing 1s $anmTrs forwards;
		}
	}
}
@media (max-width: 1024px) {
	h2 {
		font-size: ptr(45px);
		font-weight: 500;
	}

	.block-container {
		padding-left: ptr(15px);
	}

	.team-container {
		gap: ptr(126px) 0;
		height: 100%;
		grid-template-columns: 1fr;
		grid-template-rows: unset;
	}
	.team-participant {
		display: grid;
		padding-right: ptr(28px);
	}
	.img-container {
		width: ptr(134px);
		height: ptr(134px);
	}
	.name {
		margin: ptr(14px) 0 ptr(8px);
		grid-column: span 2;
	}
	.phone {
		align-self: center;
		justify-self: flex-start;
		grid-row: 1;
		grid-column: 2;
	}
	.post {
		margin-bottom: ptr(10px);
	}
	.description {
		gap: ptr(10px);
		grid-auto-flow: row;
		grid-auto-rows: max-content;
		height: unset;
		grid-column: span 2;
		margin-top: ptr(50px);
		font-size: ptr(14px);
		line-height: ptr(18px);
		p {
			width: ptr(312px);
		}
		span {
			margin-left: ptr(0px);
		}
	}
	.team-participant:nth-child(1) {
		grid-column: 1;
		grid-row: 1;
	}
	.team-participant:nth-child(2) {
		top: ptr(0px);
		grid-column: 1;
		grid-row: 2;
	}
	.team-participant:nth-child(3) {
		grid-column: 1;
		grid-row: 3;
		top: unset;
	}
}
</style>
