<template>
	<section>
		<div
			class="email-info"
			:class="{ 'min-email': data.emails.length < 3 }"
		>
			<span></span>
			<div v-for="(e, k) in data.emails" :key="k" class="mail-container">
				<div class="for-who">
					{{ e.label }}
				</div>
				<a :href="`mailto:` + e.email" class="email">
					{{ e.email }} →
				</a>
			</div>
		</div>
	</section>
</template>

<script>
import anmMixin from "@/mixins/anmMixin";
export default {
	mixins: [anmMixin],
	props: {
		data: {
			default: () => ({
				emails: [],
			}),
		},
	},
	mounted() {
		this.setObserverFunction([
			{
				name: ".email-info",
				id: 1,
				animationName: "mailShow",
				distance: 100,
			},
		]);
	},
};
</script>

<style lang="scss" scoped>
@import "../styles/blockStyles.scss";
@import "../styles/config.scss";

section {
	overflow: hidden;
}

.email-info {
	position: relative;
	margin: ptr(110px) 0 ptr(300px) ptr(280px);
	padding-top: ptr(88px);
	padding-left: ptr(185px);
	display: grid;
	grid-template-columns: ptr(385px) repeat(2, ptr(420px));
	gap: ptr(98px) ptr(100px);
	&::before {
		content: "";
		width: 0%;
		transition: 2s ease;
		height: 1px;
		background: #979797;
		position: absolute;
	}
}

.min-email {
	margin: ptr(110px) 0 ptr(-160px) ptr(280px);
}

.mail-container {
	opacity: 0;
	animation-delay: 0.2s;
	display: grid;
	gap: ptr(12px);
	animation-duration: 1s;
	animation-timing-function: $anmTrs;
	animation-fill-mode: forwards;
}

.mailShow {
	&::before {
		width: 100%;
	}
	.mail-container {
		animation-name: minfadeLeft;
	}
}

.mail-container:nth-child(5) {
	animation-delay: 0.4s;
}
.mail-container:nth-child(2) {
	animation-delay: 0.4s;
}
.mail-container:nth-child(4) {
	grid-column: 2;
	animation-delay: 0.6s;
}
.mail-container:nth-child(6) {
	animation-delay: 0.8s;
	grid-column: 2;
}

.for-who {
	font-size: ptr(16px);
	color: #595959;
}
.email {
	font-size: ptr(30px);
	text-decoration: none;
	color: $text-color;
	text-transform: uppercase;
}
@media (max-width: 1024px) {
	.email-info {
		margin: ptr(110px) 0 ptr(140px);
		padding-top: ptr(20px);
		padding-left: ptr(15px);
		grid-template-columns: ptr(309px);
		gap: ptr(40px) ptr(163px);
	}
	.mail-container:nth-child(4) {
		grid-column: 1;
	}
	.mail-container:nth-child(6) {
		grid-column: 1;
	}
	.email {
		font-size: ptr(21px);
		text-transform: uppercase;
		margin-top: ptr(10px);
	}
}
</style>
