<template>
	<section>
		<!-- <img src="/static/img/backImg.png" alt="" class="back-img" /> -->
		<div class="back-lines"></div>
		<div class="top-events">
			<event-component
				v-for="e in times.future.content"
				:key="e.project.id + e.date"
				:data="e"
				:project="selectedProject"
				:top="future"
				@getProjectId="setFilter"
			/>
		</div>
		<div class="main-data">
			<h1>
				<p class="curent-time">
					<span>
						{{ currentDate.date }}
					</span>
					<span class="dot">.</span>
					<span>
						{{ currentDate.month }}
					</span>
					<span class="dot">.</span>
					<span>
						{{ currentDate.year }}
					</span>
				</p>
				<p class="here">
					{{ $t("scrollTxt") }}
				</p>
			</h1>
		</div>
		<div class="bottom-events">
			<event-component
				v-for="(e, k) in times.past.content"
				:key="k"
				:data="e"
				:project="selectedProject"
				:top="future"
				@getProjectId="setFilter"
			/>
		</div>
	</section>
</template>

<script>
import EventComponent from "../components/EventComponent.vue";
import { http } from "../components/Services/httpService";
export default {
	components: { EventComponent },
	props: {
		scroll: {
			default: 0,
		},
	},
	data() {
		return {
			future: false,
			mainEl: null,
			loading: false,
			times: {
				future: {
					page: 1,
					content: [],
					all: [],
					maxPage: 2,
				},
				past: {
					page: 1,
					content: [],
					all: [],
					maxPage: 2,
				},
			},
			winHeight: 0,
			docHeight: 10000,
			selectedProject: "",
		};
	},
	methods: {
		getTimeLife(future) {
			const time = future ? "future" : "past";
			if (this.times[time].page >= this.times[time].maxPage) {
				return;
			}
			this.loading = true;
			const last = this.mainEl.getBoundingClientRect().top;
			let getReq = `/api/timelife?lang=${this.$i18n.locale}&page=${this.times[time].page}&future=${future}`;
			// if (this.selectedProject) {
			// 	getReq += `&projectId=${this.selectedProject}`;
			// }
			http.get(getReq).then((response) => {
				const bl = response.data.blocks;
				if (bl.length > 0) {
					if (future) {
						this.times[time].all.unshift(...bl);
						setTimeout(() => {
							this.calcScroll(last);
						}, 0);
					} else {
						this.times[time].all.push(...bl);
					}
					this.docHeight = document.documentElement.scrollHeight;
					this.filterPage(time);
					this.times[time].maxPage += 1;
				}
				this.times[time].page += 1;
				this.loading = false;
			});
		},
		calcScroll(last) {
			this.mainEl.scrollIntoView();
			window.scrollTo(0, window.scrollY - 1 - last);
		},
		setFilter(opt) {
			this.selectedProject = opt.id;
			setTimeout(() => {
				opt.el.scrollIntoView();
				window.scrollTo(0, window.scrollY - 1 - opt.top);
			}, 0);
		},
		filterPage(type) {
			const checkId = (el) => {
				return el.project.id == this.selectedProject;
			};
			if (this.selectedProject === "") {
				this.times[type].content = [...this.times[type].all];
			} else {
				this.times[type].content = this.times[type].all.filter((e) =>
					checkId(e)
				);
			}
		},
	},
	computed: {
		currentDate() {
			const addZero = (num) => {
				return num > 9 ? num : "0" + num;
			};
			const d = new Date();
			return {
				date: addZero(d.getDate()),
				month: addZero(d.getMonth()),
				year: d.getFullYear(),
			};
		},
	},

	watch: {
		scroll(newVal) {
			if (this.loading) {
				return;
			}
			if (newVal < this.winHeight) {
				this.getTimeLife(true);
			} else if (newVal > this.docHeight - this.winHeight) {
				this.getTimeLife(false);
			}
		},
		selectedProject() {
			this.filterPage("future");
			this.filterPage("past");
		},
	},

	mounted() {
		this.mainEl = this.$el.querySelector(".main-data");
		this.mainEl.scrollIntoView();
		document.title = "P/25 | TimeLife";
		this.winHeight = window.innerHeight;
		this.getTimeLife(false);
		this.getTimeLife(true);
	},
};
</script>

<style lang="scss" scoped>
@import "../components/styles/config.scss";

section {
	position: relative;
	padding: ptr(140px) 0;
}

.back-lines {
	position: absolute;
	top: 0;
	left: ptr(686px);
	display: grid;
	gap: ptr(12px);
	height: 100%;
	width: ptr(12px);
	animation: fastDown 1.5s cubic-bezier(0.33, 1, 0.68, 1) forwards;
	background: repeating-linear-gradient(
		-0deg,
		rgba(255, 255, 255, 0.37) 19px,
		rgba(255, 255, 255, 0.37) 20px,
		rgba(0, 0, 0, 0) 20px,
		rgba(0, 0, 0, 0) 36px
	);
}

.back-img {
	width: 100%;
	position: fixed;
	top: 0;
}

.main-data {
	width: 100%;
	height: 100vh;
	margin: ptr(120px) 0;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.scroll-txt {
	position: absolute;
	font-size: ptr(16px);
	font-weight: 300;
	text-transform: uppercase;
	right: ptr(83px);
	margin-bottom: ptr(20px);
	opacity: 0;
	animation: fade 1s 1s $anmTrs forwards;
}
h1 {
	margin: 0 0 0 ptr(-5px);
	position: relative;
	left: ptr(95px);
	width: ptr(550px);
}
.curent-time {
	font-size: ptr(80px);
	font-weight: 500;
	line-height: ptr(70px);
	span {
		display: inline-block;
		opacity: 0;
		background: linear-gradient(#585e74, #d8d8d8);
		background-clip: text;
		color: transparent;
	}
	@for $i from 1 through 15 {
		span:nth-child(#{$i}) {
			animation: minfadeUp 0.8s $anmTrs forwards;
			animation-delay: 0.5s + $i * 0.08s;
		}
	}
}
.here {
	color: #d9d9d9;
	font-size: ptr(14px);
	font-weight: 300;
	line-height: ptr(22px);
	text-transform: uppercase;
	opacity: 0;
	white-space: break-spaces;
	margin-top: ptr(26px);
	animation: fade 1s 1s $anmTrs forwards;
}

.bottom-events,
.top-events {
	display: grid;
	position: relative;
	gap: ptr(300px);
	justify-content: center;
}

@media (max-width: 1024px) {
	h1 {
		left: ptr(20px);
		width: ptr(340px);
	}
	.main-data {
		margin: ptr(20px) 0;
		align-items: flex-start;
	}
	.back-lines {
		display: none;
	}
	.curent-time {
		font-size: ptr(64px);
	}
	.here {
		margin-top: ptr(16px);
		line-height: ptr(18px);
		font-size: ptr(12px);
	}
	.scroll-txt {
		display: none;
	}
	.bottom-events,
	.top-events {
		gap: ptr(126px);
		justify-content: flex-start;
	}
}
</style>
