<template>
	<div class="block-container">
		<div class="by-quote">
			<p>
				<span v-for="(l, k) of data.author" :key="k" class="letter">{{
					l
				}}</span>
			</p>
			<span class="side-text">{{ data.position }}</span>
		</div>
		<div class="cnt">
			<div class="quote-container">
				<img
					class="qvt"
					src="/static/icons/qvt.svg"
					alt="Начало цитаты"
				/>
				<p class="main-text">
					{{ data.text }}
				</p>
				<img class="signature" :src="data.signature" alt="Подпись" />
			</div>
		</div>
	</div>
</template>

<script>
import anmMixin from "@/mixins/anmMixin";
export default {
	mixins: [anmMixin],
	props: {
		data: {
			default: () => ({
				name: "Alexander Velikanov",
				position: "",
				quote: "",
				img: "",
			}),
		},
	},
	mounted() {
		this.setObserverFunction([
			{
				name: ".by-quote",
				animationName: "quoteActive",
			},
			{
				name: ".qvt",
				animationName: "minfadeUp",
			},
			{
				name: ".signature",
				animationName: "minfadeUp",
				delay: 100,
			},
			{
				name: ".main-text",
				animationName: "minfadeUp",
				delay: 100,
			},
		]);
	},
};
</script>

<style lang="scss" scoped>
@import "../styles/blockStyles.scss";
@import "../styles/config.scss";

.block-container {
	padding-left: ptr(287px);
}
.by-quote {
	display: flex;
	position: relative;
	font-size: ptr(30px);
	font-weight: 300;
	text-transform: uppercase;
	padding-top: ptr(34px);
	p {
		display: flex;
		white-space: break-spaces;
		align-items: flex-start;
		overflow: hidden;
	}
	&::before {
		content: "";
		width: 0%;
		height: 1px;
		top: 0;
		position: absolute;
		background: #979797;
		transition: 1s $anmTrs;
	}
}
.side-text {
	margin: ptr(5px) ptr(45px);
	text-transform: none;
	opacity: 0;
	font-size: ptr(14px);
}
.cnt {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.quote-container {
	margin-top: ptr(143px);
	padding-left: ptr(335px);
	p {
		font-size: ptr(50px);
		opacity: 0;
		font-weight: 100;
		width: ptr(624px);
		margin-bottom: ptr(192px);
	}
}
.qvt {
	opacity: 0;
	margin-bottom: ptr(88px);
}
.signature {
	opacity: 0;
	object-fit: contain;
	width: ptr(200px);
	margin-bottom: ptr(100px);
}
.letter {
	opacity: 0;
}
.quoteActive {
	@for $i from 1 through 40 {
		.letter:nth-child(#{$i}) {
			animation: trsTop 0.7s $anmTrs forwards;
			animation-delay: $i * 0.02s;
		}
	}
	.side-text {
		animation: fade 1.5s 0.5s $anmTrs forwards;
	}
	&::before {
		width: 100%;
	}
}
@media (max-width: 1024px) {
	.block-container {
		display: grid;
		justify-content: unset;
		padding-left: ptr(15px);
	}
	.by-quote {
		flex-direction: column;
		flex-direction: column;
		grid-row: 2;
		font-size: ptr(22px);
		margin-top: ptr(70px);
		padding-top: ptr(24px);
	}
	.side-text {
		margin: ptr(5px) 0;
	}
	.cnt {
		justify-content: unset;
		align-items: flex-start;
	}
	.quote-container {
		margin-top: 0;
		padding-left: 0;
		p {
			font-size: ptr(29px);
			font-weight: 100;
			width: ptr(323px);
			margin-bottom: ptr(56px);
		}
	}
	.qvt {
		width: ptr(85px);
		margin-bottom: ptr(42px);
	}
	.signature {
		width: ptr(145px);
	}
}
</style>
