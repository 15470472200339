<template>
	<div class="paralax-container">
		<a
			:href="data.url"
			target="_blank"
			@mouseenter="checkCircle($event)"
			@mousemove="getPos"
			@mouseleave="leaveCircle"
			:class="{
				'one-word': data.text.split(' ').length === 1,
			}"
			:style="
				`background: ${data.color};
				transform: translate3d(${-circle.x / 2.5}px,${-circle.y / 2.5}px,0)`
			"
		>
			<p
				:style="
					`transform: translate3d(${-circle.x}px,${-circle.y}px,0)`
				"
			>
				{{ data.text }}
			</p>
		</a>
	</div>
</template>

<script>
import mouseFollowMixin from "@/mixins/mouseFollowMixin";
import anmMixin from "@/mixins/anmMixin";
export default {
	mixins: [mouseFollowMixin, anmMixin],
	props: {
		data: {
			default: () => ({}),
		},
		scroll: {
			default: 0,
		},
	},
	watch: {
		scroll(newVal) {
			this.$el.style.transform = `translate3d(0,${-newVal * 0.2}px,0)`;
		},
	},
};
</script>

<style lang="scss" scoped>
@import "./styles/config.scss";

.paralax-container {
	position: absolute;
	left: ptr(400px);
	top: ptr(60px);
	opacity: 0;
	&:hover {
		will-change: transform;
		a {
			will-change: transform;
			p {
				will-change: transform;
			}
		}
	}
}

a {
	border-radius: 50%;
	background: #496066;
	width: ptr(187px);
	height: ptr(187px);
	display: flex;
	align-items: center;
	justify-content: center;
	text-decoration: none;

	span {
		pointer-events: none;
		margin-left: ptr(20px);
	}
	p {
		width: ptr(100px);
		text-align: center;
		pointer-events: none;
		color: black;
		font-size: ptr(16px);
		font-weight: 600;
		padding-top: ptr(7px);
		text-transform: uppercase;
	}
}

.one-word {
	p {
		display: flex;
		justify-content: center;
		width: 100%;
	}
	span {
		display: none;
	}
}
</style>
