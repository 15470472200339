<template>
	<div class="block-container">
		<h2 v-if="!onlyWorld">
			<span>
				{{ $t("map[0]") }}
			</span>
			<br />
			<span>
				{{ $t("map[1]") }}
			</span>
		</h2>
		<div v-if="!onlyWorld" class="map-selector">
			<p
				@click="worldMap = true"
				class="slc"
				:class="{ selected: worldMap }"
			>
				World <span>→</span>
			</p>
			<p
				@click="worldMap = false"
				class="slc"
				:class="{ selected: !worldMap }"
			>
				Russia <span>→</span>
			</p>
		</div>
		<div class="map-container" @touchstart="hideGuid = true">
			<!-- <div class="fade-guid" :class="{ hide: hideGuid }">
				<img src="/static/icons/minArr.svg" alt="стрелка в левый бок" />
				<img
					src="/static/icons/elp.svg"
					alt="круг изображающий перемещение пальцем"
				/>
				<img
					src="/static/icons/minArr.svg"
					alt="стрелка в правый бок"
				/>
			</div> -->
			<div class="map world" :class="{ selected: worldMap }">
				<div
					v-for="(p, k) in data.world"
					:key="k"
					class="point"
					:style="{
						left: (isMobile ? p.x / 2 : p.x) + 'rem',
						top: (isMobile ? p.y / 2 : p.y) + 'rem',
					}"
				>
					<div class="hover-text">
						<p class="city">
							{{ p.city }}
						</p>
						<p class="address">
							{{ p.address }}
						</p>
					</div>
				</div>
				<img src="/static/img/map.svg" alt="Карта мира" />
			</div>
			<div class="map russian" :class="{ selected: !worldMap }">
				<div
					v-for="(p, k) in data.ru"
					:key="k"
					class="point"
					:style="{
						left: (isMobile ? p.x / 1.6 : p.x) + 'rem',
						top: (isMobile ? p.y / 1.8 : p.y) + 'rem',
					}"
				>
					<div class="hover-text">
						<p class="city">
							{{ p.city }}
						</p>
						<p class="address">
							{{ p.address }}
						</p>
					</div>
				</div>
				<img src="/static/img/map-ru.svg" alt="Карта России" />
			</div>
		</div>
	</div>
</template>

<script>
import anmMixin from "@/mixins/anmMixin";
export default {
	mixins: [anmMixin],
	props: {
		data: {
			default: () => ({}),
		},
		onlyWorld: {
			default: false,
		},
	},
	data() {
		return {
			worldMap: true,
			isMobile: false,
			hideGuid: false,
			maps: {
				world: 0,
				ru: 0,
			},
		};
	},
	mounted() {
		const elems = [
			{
				name: ".map-container",
				id: 3,
				animationName: "minfadeUp",
				distance: 300,
			},
		];
		if (!this.onlyWorld) {
			elems.push(
				{
					name: "h2",
					id: 1,
					animationName: "sideWords",
					distance: 300,
				},
				{
					name: ".map-selector",
					id: 2,
					animationName: "fadeLeft",
					distance: 300,
				}
			);
		}
		this.setObserverFunction(elems);
		setTimeout(() => {
			const w = this.$el.querySelector(".world");
			const r = this.$el.querySelector(".russian");
			this.maps = {
				world: w.scrollWidth,
				ru: r.scrollWidth,
			};
			w.scrollTo(w.scrollWidth / 4, 0);
			// r.scrollTo(r.scrollWidth / 4, 0);
		}, 100);
		this.worldMap = !this.data.showRussiaFirst;
		this.isMobile = window.innerWidth < 1024;
	},
};
</script>

<style lang="scss" scoped>
@import "../styles/blockStyles.scss";
@import "../styles/config.scss";

.block-container {
	padding-left: ptr(332px);
}

h2 {
	font-size: ptr(60px);
	font-weight: 300;
	line-height: ptr(66px);
	position: relative;
	left: ptr(615px);
	width: fit-content;
	span {
		opacity: 0;
	}
	span:nth-child(3) {
		margin-left: ptr(60px);
	}
	margin: 0 0 ptr(150px);
}
.map-selector {
	display: flex;
	opacity: 0;
	justify-content: center;
	margin: 0 ptr(-180px) ptr(120px) 0;
	span {
		transition: $trs;
		opacity: 0;
	}
}
.slc {
	font-size: ptr(60px);
	font-weight: 300;
	text-transform: uppercase;
	display: flex;
	transition: $trs;
	opacity: 0.5;
	&:hover span {
		opacity: 1;
	}
	&:nth-child(2) {
		margin-left: ptr(20px);
	}
}

.map-container {
	height: ptr(740px);
	opacity: 0;
}

.map {
	opacity: 0;
	position: absolute;
	pointer-events: none;
	transition: $trs;
	img {
		width: ptr(1390px);
	}
}

.selected {
	opacity: 1;
	pointer-events: all;
	&:hover span {
		opacity: 0;
	}
}

.point {
	background-image: url("/static/icons/point.svg");
	background-repeat: no-repeat;
	background-size: contain;
	position: absolute;
	left: 0;
	top: 0;
	width: ptr(39px);
	height: ptr(60px);
	margin: ptr(-60px) 0 0 ptr(-19px);
	&:hover {
		.hover-text {
			opacity: 1;
		}
	}
}
.world {
	margin-left: ptr(-40px);
}
.russian {
	margin-left: ptr(40px);
	img {
		width: ptr(1210px);
	}
}
.hover-text {
	position: absolute;
	bottom: ptr(-5px);
	right: ptr(-230px);
	width: ptr(216px);
	border-radius: ptr(14px);
	border: 1px solid #a8a8a8;
	background: black;
	padding: ptr(35px) ptr(34px) ptr(30px);
	box-sizing: border-box;
	z-index: 3;
	pointer-events: none;
	opacity: 0;
	transition: $trs;
}
.city {
	font-weight: 500;
	font-size: ptr(18px);
	margin-bottom: ptr(18px);
}
.address {
	font-weight: 300;
	font-size: ptr(14px);
}
.fade-guid {
	display: none;
}

@media (max-width: 1024px) {
	.block-container {
		padding: 0;
		align-items: flex-start;
	}
	h2 {
		left: ptr(15px);
		font-size: ptr(45px);
		font-weight: 500;
		line-height: ptr(41px);
		margin-bottom: ptr(40px);
	}
	.map-container {
		height: ptr(400px);
		width: 100%;
		position: relative;
	}
	.map-selector {
		margin: 0 0 ptr(71px) ptr(15px);
	}
	// .fade-guid {
	// 	display: flex;
	// 	justify-content: center;
	// 	pointer-events: none;
	// 	position: absolute;
	// 	z-index: 3;
	// 	background: rgba(0, 0, 0, 0.505);
	// 	width: 100%;
	// 	height: 100%;
	// 	transition: $trs;
	// 	img {
	// 		opacity: 0.6;
	// 		margin: 0 ptr(10px);
	// 		width: ptr(15px);
	// 		$t: 6s;
	// 		&:nth-child(1) {
	// 			animation: opcL $t ease infinite;
	// 			width: ptr(10px);
	// 		}
	// 		&:nth-child(2) {
	// 			animation: move $t ease infinite;
	// 		}
	// 		&:nth-child(3) {
	// 			width: ptr(10px);
	// 			transform: rotate(180deg);
	// 			animation: opcR $t ease infinite;
	// 		}
	// 	}
	// }
	@keyframes move {
		from,
		75%,
		100% {
			opacity: 0.6;
			transform: translateX(0px);
		}
		25% {
			opacity: 1;
			transform: translateX(10px);
		}
		50% {
			opacity: 1;
			transform: translateX(-10px);
		}
	}
	@keyframes opcR {
		from,
		75%,
		100% {
			opacity: 0.6;
		}
		25% {
			opacity: 1;
		}
		50% {
			opacity: 0.6;
		}
	}
	@keyframes opcL {
		from,
		75%,
		100% {
			opacity: 0.6;
		}
		25% {
			opacity: 0.6;
		}
		50% {
			opacity: 1;
		}
	}
	.hide {
		opacity: 0;
	}
	.slc {
		font-size: ptr(30px);
	}
	.map {
		width: 100vw;
		overflow: scroll;
		img {
			width: ptr(695px);
		}
	}
	.world {
		margin-left: 0;
	}
	.russian {
		margin-left: 0;
		img {
			width: ptr(695px);
		}
	}
	.point {
		width: ptr(22px);
		height: ptr(33px);
		margin: ptr(-33px) 0 0 ptr(-22px);
	}
	.hover-text {
		right: ptr(-190px);
		bottom: unset;
		top: ptr(5px);
		width: ptr(180px);
		padding: ptr(25px) ptr(24px) ptr(20px);
	}
}
</style>
