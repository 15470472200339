<template>
	<div :class="rootClass">
		<svg viewBox="0 0 14 13">
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M12.099 6.50006L7.10145 11.4976L7.95888 12.355L13.9609 6.35295L13.8049 6.19691L13.9609 6.04087L7.95888 0.0388184L7.10145 0.896255L11.7053 5.50006L0.499695 5.50006V6.50006L12.099 6.50006Z"
			/>
		</svg>
	</div>
</template>

<script>
export default {
	name: "Arrow",
	props: {
		direction: {
			type: String,
			default: "right",
		},
	},
	computed: {
		rootClass() {
			return `arrow arrow-${this.direction}`;
		},
	},
};
</script>

<style lang="scss" scoped>
@import "../../styles/config";

.arrow.arrow-left {
	transform: rotate(180deg);
}

.arrow {
	border: 1px solid #d6d6d6;
	border-radius: 50%;
	width: ptr(77px);
	height: ptr(77px);
	display: flex;
	align-items: center;
	justify-content: center;
	transition: 0.2s $anmTrs;
	svg {
		width: ptr(13px);
		transition: 0.2s $anmTrs;
		stroke: #d6d6d6;
		transform: translateY(1px);
	}
}

.arrow:hover {
	border-color: white;

	svg {
		stroke: white;
	}
}

@media (max-width: 1024px) {
	.arrow {
		border: unset;
		width: ptr(30px);
		height: ptr(30px);
		svg {
			width: ptr(22px);
		}
	}
}
</style>
