<template>
	<section>
		<div class="we-are">
			<!-- <img class="back-img" src="/static/img/backImg.png" alt="" /> -->
			<h1 class="fadeSpanList">
				<template
					v-for="(l, k) in `What is${mobile ? '\n' : ' '}Placebo/25`"
					:key="k"
				>
					<img
						v-if="l === '/'"
						src="/static/icons/line.svg"
						alt="/"
					/>
					<br v-else-if="l === '\n'" />
					<span v-else>
						{{ l }}
					</span>
				</template>
			</h1>
			<div class="sub-text">
				{{ $t("wePlaceboDesc") }}
			</div>
		</div>
		<div class="content">
			<description :scroll="scroll" :data="blocksData[0]" />
			<one-video :data="blocksData[3]" />
			<div class="history-and-culture">
				<h2>
					<span>{{ $t("cAndh[0]") }}</span
					><br />
					<span>+ {{ $t("cAndh[1]") }}</span>
				</h2>
				<p class="history-sub">
					<span
						v-for="(p, k) in parseTxt(
							$t('hStudied'),
							mobile ? 216 : 307,
							mobile ? 14 : 16
						)"
						:key="k"
					>
						{{ p }}
					</span>
				</p>
				<div class="our-mission">
					<span></span> {{ $t("ourMission") }}
				</div>
				<div class="connect-circles">
					<div class="circle disign">
						<span>
							design
						</span>
					</div>
					<div class="plus">
						+
					</div>
					<div class="circle kinesthetics">
						<span>
							kinesthetics
						</span>
					</div>
				</div>
			</div>
			<!-- <numbers
				:scroll="scroll"
				:data="blocksData[1]"
				:num="blocksData.length"
			/> -->
			<div class="arrow-container">
				<svg class="arrow-down" viewBox="0 0 83 442">
					<path
						d="M82.2529 399.945L41.1265 441.002L0 399.945L5.86523 394.01L40.9841 429.484L76.3877 394.01L82.2529 399.945Z"
						fill="#D9D9D9"
					/>
					<path d="M45 437H37V0H45V437Z" fill="#D9D9D9" />
				</svg>
				<infinite-text-line :data="blocksData[2]" />
			</div>
			<min-description
				:data="{ title: $t('people'), content: $t('careful') }"
			/>
			<div class="footer">
				<p class="work-with">
					Work with Placebo/25
				</p>
				<button class="HOVER" @click="goToContacts">
					<span></span>
					<p>{{ $t("sendCV") }} →</p>
				</button>
			</div>
			<router-link to="/timelife" class="next">
				NEXT →
			</router-link>
			<Footer />
		</div>
	</section>
</template>

<script>
import Description from "../components/Blocks/Description.vue";
import Footer from "../components/Blocks/Footer.vue";
import InfiniteTextLine from "../components/Blocks/InfiniteTextLine.vue";
import MinDescription from "../components/Blocks/MinDescription.vue";
// import Numbers from "../components/Blocks/Numbers.vue";
import OneVideo from "../components/Blocks/OneVideo.vue";
import { http } from "../components/Services/httpService";
import anmMixin from "../mixins/anmMixin";

export default {
	mixins: [anmMixin],
	components: {
		Description,
		OneVideo,
		// Numbers,
		MinDescription,
		Footer,
		InfiniteTextLine,
	},
	props: {
		scroll: {
			default: 0,
		},
		mobile: {
			default: false,
		},
	},
	data() {
		return {
			aboutData: [],
		};
	},
	methods: {
		getAbout() {
			http.get(`/api/pages/about?lang=${this.$i18n.locale}`).then(
				(response) => {
					this.aboutData = response.data.blocks;
				}
			);
		},
		goToContacts() {
			this.$router.push("/Contact/#emails");
		},
	},
	computed: {
		blocksData() {
			const blocks = [];
			for (let e of this.aboutData) {
				blocks.push(e.id ? e.data : {});
			}
			return blocks;
		},
	},
	mounted() {
		this.setObserverFunction([
			{
				name: "h2",
				animationName: "sideWords",
				distance: 300,
			},
			{
				name: ".connect-circles",
				animationName: "circleIn",
				distance: 300,
			},
			{
				name: ".history-sub",
				animationName: "fadeGrm",
			},
			{
				name: ".our-mission",
				animationName: "fadeUp",
			},
			{
				name: ".arrow-down",
				animationName: "circleIn",
			},
		]);
		window.scrollTo(0, 0);
		document.title = "P/25 | About";
	},
	created() {
		this.getAbout();
	},
};
</script>

<style lang="scss" scoped>
@import "../components/styles/config.scss";

.clipDown {
	path:nth-child(1) {
		animation: clipUp 0.6s 0.45s $anmTrs forwards;
	}

	path:nth-child(2) {
		animation: clipDown $timing $anmTrs forwards;
	}
}

.we-are {
	width: 100%;
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;

	h1 {
		white-space: pre-wrap;
		font-size: ptr(70px);
		margin: 0;
		text-transform: uppercase;
		font-weight: 500;
		line-height: ptr(60px);
		text-align: center;

		span {
			display: inline-block;
			opacity: 0;
			background: #d5daec;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
		}
		img {
			width: ptr(36px);
			opacity: 0;
		}
	}
}

.back-img {
	width: 100%;
	position: absolute;
	pointer-events: none;
	top: 0;
}

.sub-text {
	position: relative;
	left: ptr(124px);
	width: ptr(250px);
	top: ptr(140px);
	font-size: ptr(14px);
	line-height: ptr(20px);
	text-transform: uppercase;
	opacity: 0;
	animation: minfadeUp 1s 0.8s $anmTrs forwards;
	white-space: pre-line;
}

.content {
	display: grid;
	overflow: hidden;
	gap: ptr(400px);
}

.history-and-culture {
	width: ptr(1500px);
	position: relative;
	justify-self: center;
}

h2 {
	font-size: ptr(141px);
	font-weight: 300;
	margin: 0;
	text-transform: uppercase;
	position: relative;
	width: fit-content;
	left: ptr(502px);

	span {
		opacity: 0;
	}

	span:nth-child(1) {
		margin-left: ptr(230px);
	}
}

.history-sub {
	margin-top: ptr(97px);
	position: relative;
	left: ptr(740px);
	width: ptr(307px);
	font-size: ptr(16px);
	font-weight: 300;
	white-space: break-spaces;
	line-height: ptr(29px);

	span {
		opacity: 0;
		display: block;
	}
}

.our-mission {
	width: ptr(524px);
	margin-top: ptr(323px);
	font-size: ptr(40px);
	font-weight: 300;
	position: relative;
	left: ptr(740px);
	text-transform: uppercase;
	white-space: break-spaces;
	opacity: 0;

	span {
		margin-left: ptr(100px);
	}
}

.connect-circles {
	position: relative;
	left: ptr(-10px);
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: ptr(180px);
	height: ptr(855px);
	text-transform: uppercase;
	font-size: ptr(60px);
	font-weight: 300;
}

.circle {
	width: ptr(853px);
	height: ptr(853px);
	border-radius: 50%;
	border: 2px solid $text-color;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	opacity: 0;

	span {
		opacity: 0;
	}
}

.plus {
	opacity: 0;
}

.disign {
	left: 0;
	transform: translate3d(ptr(-200px), 0, 0) rotate(90deg);
}

.kinesthetics {
	right: 0;
	transform: translate3d(ptr(200px), 0, 0) rotate(0deg);
}

.circleIn {
	.disign {
		animation: fadeRotateLeft 2s cubic-bezier(0.35, 0.01, 0.45, 1.01)
			forwards;

		span {
			animation: fade 2s 1.5s $anmTrs forwards;
		}
	}

	.kinesthetics {
		animation: fadeRotateRight 2s cubic-bezier(0.35, 0.01, 0.45, 1.01)
			forwards;

		span {
			animation: fade 2s 1.7s $anmTrs forwards;
		}
	}

	.plus {
		animation: fade 2s 1.6s $anmTrs forwards;
	}
}

@keyframes fadeRotateLeft {
	from {
		opacity: 0;
		transform: translate3d(ptr(-200px), 0, 0);
	}
	to {
		opacity: 1;
		transform: translate3d(ptr(0px), 0, 0);
	}
}

@keyframes fadeRotateRight {
	from {
		opacity: 0;
		transform: translate3d(ptr(200px), 0, 0) rotate(0deg);
	}
	to {
		opacity: 1;
		transform: translate3d(ptr(0px), 0, 0) rotate(-90deg);
	}
}

.arrow-container {
	position: relative;
}

.arrow-down {
	position: absolute;
	width: ptr(82px);
	left: ptr(1340px);
	top: ptr(-606px);

	path {
		opacity: 0;
	}
}

.footer {
	padding: ptr(56px) 0 0;
	margin-bottom: ptr(-100px);
	position: relative;
	padding-left: ptr(287px);
	display: grid;
	grid-template-columns: ptr(663px) 1fr;
	align-items: center;

	&::before {
		content: "";
		width: calc(100% - #{ptr(287px)});
		height: 1px;
		background: #979797;
		position: absolute;
		top: 0;
		left: ptr(287px);
	}

	button {
		width: ptr(185px);
		height: ptr(46px);
		border-radius: ptr(100px);
		border: 2px solid rgba(255, 255, 255, 0.4);
		color: $text-color;
		display: flex;
		align-items: center;
		justify-content: center;
		background: unset;
		text-transform: uppercase;

		p {
			position: relative;
			pointer-events: none;
			z-index: 2;
			transition: $trs;
		}
	}
}

@media (max-width: 1024px) {
	.we-are {
		height: 100vh;
		margin-bottom: ptr(30px);
		justify-content: flex-start;
		align-items: flex-start;
		padding: ptr(275px) 0 0 ptr(15px);
		box-sizing: border-box;

		h1 {
			font-size: ptr(46px);
			line-height: ptr(45px);
			text-align: start;
			img {
				width: ptr(23px);
			}
		}

		&::after {
			content: "";
			width: calc(100% - #{ptr(15px)});
			height: 1px;
			position: absolute;
			bottom: 0;
			left: ptr(15px);
			background: #464646;
		}
	}
	.content {
		gap: ptr(100px);
	}
	.arrow-container {
		margin: ptr(100px) 0 ptr(100px);
	}
	.arrow-down {
		top: ptr(-360px);
		width: ptr(55px);
		left: ptr(260px);
	}
	.sub-text {
		left: ptr(0px);
		top: ptr(20px);
		font-size: ptr(10px);
	}
	.history-and-culture {
		width: 100vw;
	}
	h2 {
		left: ptr(-15px);
		font-size: ptr(57px);
		font-weight: 400;
		line-height: ptr(60px);

		span:nth-child(1) {
			margin-left: ptr(90px);
		}
	}
	.next {
		justify-self: center;
	}
	.history-sub {
		width: ptr(270px);
		left: ptr(78px);
		font-size: ptr(14px);
		line-height: ptr(18px);
	}
	.our-mission {
		margin-top: ptr(170px);
		font-size: ptr(22px);
		left: ptr(15px);
		width: ptr(350px);

		span {
			margin-left: ptr(60px);
		}
	}
	.connect-circles {
		margin: ptr(100px) 0;
		height: ptr(291px);
		font-size: ptr(20px);
	}
	.circle {
		width: ptr(291px);
		height: ptr(291px);
	}
	.disign {
		left: ptr(-66px);
		transform: translate3d(ptr(-200px), 0, 0) rotate(90deg);
	}
	.kinesthetics {
		right: ptr(-66px);
		transform: translate3d(ptr(200px), 0, 0) rotate(0deg);
	}
	.infinite-line {
		font-size: ptr(45px);
	}
	.mobile {
		display: flex;
		margin-left: ptr(-700px);
	}
	.footer {
		padding: 0;
		grid-template-columns: 1fr;

		&::before {
			content: unset;
		}

		.work-with {
			display: none;
		}
		.HOVER {
			justify-self: center;
		}
	}
	.span-line {
		margin-right: ptr(20px);
		gap: ptr(20px);
	}
}
</style>
